import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { radialConstructor } from './utils'

const BasePaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  // border: `solid 1px ${theme.palette.secondary.main}`,
  borderRadius: theme.spacing(2),
  maxWidth: 500,
  width: 'calc(100vw - 1.8rem)',
  margin: theme.spacing(2),
  boxShadow: `${(theme.palette.mode === 'light') ? '#33333333' : theme.palette.primary.dark} 0px 4px 21px`,
  background: (theme.palette.mode === 'light')
    ? radialConstructor(theme.palette.primary.main, theme.palette.primary.main)
    : radialConstructor(theme.palette.primary.light, theme.palette.primary.dark),
  padding: theme.spacing(2),
  '& p': {
    fontSize: '.75rem',
  },
}))

export default BasePaper
// background: rgb(251,194,62);
// background: linear-gradient(161deg, rgba(251,194,62,1) 0%, rgba(133,109,52,1) 43%, rgba(44,44,44,1) 100%);
