import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'

import { ethers } from 'ethers'

import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useAppSelector } from '../../app/hooks'

import {
  ClaimButton,
} from '../buttons'
import { BasePaper } from '../papers'
import { Currency } from '../../entities'
import { getChainData, prettyFormat } from '../../helpers/utilities'
import { useGetStakeInfo } from '../../hooks/governance'
import { ANALYTICS_URI } from '../../helpers/constants'

interface StakeInfoBoxProps {
  stakeCurrency: Currency
}

const StakeInfoBox = ({
  stakeCurrency,
}: StakeInfoBoxProps): JSX.Element => {
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const chain = getChainData(chainId)
  const { library: provider } = useWeb3React<Web3Provider>()

  const { result: stakeResult } = useGetStakeInfo({
    provider,
    chainId,
    account,
    stakingAddress: chain?.governance?.stakingAddress,
  })

  const weeklyRewardRateFmt = (stakeResult && stakeCurrency)
    ? prettyFormat(stakeResult.weeklyRewardRate, stakeCurrency.decimals, 4) : '0'

  const aprFmt = (stakeResult) ? stakeResult.apr : '-'

  const userInfo = useMemo(() => ((stakeResult) ? [
    {
      key: 'uWeeklyRewardRate',
      label: 'Your weekly reards',
      result: prettyFormat(stakeResult.uWeeklyRewardRate, stakeCurrency.decimals, 4).toLocaleString(),
    },
    {
      key: 'apr',
      label: 'Annual stake APR (%)',
      result: stakeResult.apr.toLocaleString(),
    },
    {
      key: 'earned',
      label: 'Unclaimed rewards',
      result: prettyFormat(stakeResult.earned, stakeCurrency.decimals, 4).toLocaleString(),
      additionalComponent: (
        <ClaimButton
          variant="outlined"
          disabled={stakeResult.earned.eq(ethers.constants.Zero) || typeof chain?.governance === 'undefined'}
          contract={chain?.governance?.stakingAddress}
          action="getReward"
        />
      ),
    },
  ] : []), [stakeResult, chain, stakeCurrency])

  const poolInfo = useMemo(() => ((stakeResult) ? [
    {
      key: 'tvl',
      label: `Total ${stakeCurrency.symbol} staked`,
      result: prettyFormat(stakeResult.tvl, stakeCurrency.decimals, 4).toLocaleString(),
    },
    {
      key: 'uTvl',
      label: `Your ${stakeCurrency.symbol} staked`,
      result: prettyFormat(stakeResult.uTvl, stakeCurrency.decimals, 4).toLocaleString(),
    },
    {
      key: 'liquidityShare',
      label: `Your ${stakeCurrency.symbol} stake pool share (%)`,
      result: stakeResult.liquidityShare.toLocaleString(),
    },
    {
      key: 'contract',
      label: `View ${stakeCurrency.symbol} contract`,
      link: `${chain?.explorerURL}/address/${stakeCurrency.address}/contracts`,
    },
    {
      key: 'chart',
      label: `View ${stakeCurrency.symbol} chart`,
      link: `${ANALYTICS_URI}/token/${stakeCurrency.address}`,
    },
  ] : []), [chain, stakeResult, stakeCurrency])

  if (stakeResult?.uTvl?.gt(ethers.constants.Zero)) {
    return (
      <>
        <BasePaper>
          <Grid container>
            {userInfo.map((info) => (
              <Grid
                key={info.key}
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      whiteSpace: 'nowrap',
                      marginRight: 0.5,
                    }}
                  >
                    {info.label}
                  </Typography>
                  {info.additionalComponent}
                </Box>
                <Typography
                  variant="subtitle2"
                >
                  {info.result}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </BasePaper>
        <BasePaper>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0.5,
                marginBottom: 1,
              }}
            >
              <Typography
                variant="h6"
              >
                {`Community ${stakeCurrency.symbol} Stake`}
              </Typography>
            </Grid>
            {poolInfo.map((info) => (
              <Grid
                key={info.key}
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 0.5,
                }}
              >
                {!info.link ? (
                  <>
                    <Typography
                      variant="subtitle2"
                    >
                      {info.label}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                    >
                      {info.result}
                    </Typography>
                  </>
                ) : (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={info.link}
                    color="secondary"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 500,
                    }}
                  >
                    {info.label}
                  </Link>
                )}
              </Grid>
            ))}
          </Grid>
        </BasePaper>
      </>
    )
  }

  return (
    <Box m={2}>
      <Typography
        variant="subtitle2"
        color="secondary"
      >
        {`Weekly rewards: ${weeklyRewardRateFmt} `
          + `${stakeCurrency.symbol} / week (${aprFmt}% APR)`}
      </Typography>
    </Box>
  )
}

export default StakeInfoBox
