import { ethers, BigNumber } from 'ethers'

import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { HookProvider } from '..'
import { getChainData, getSighash } from '../../helpers/utilities'
import { useMulticall2 } from '../multicall'
import { stakingRewardsInterface } from './utils'

interface InfoProps {
  provider?: HookProvider
  stakingAddress?: string
  account: string
  chainId: number
}

interface StakeResult {
  tvl: BigNumber
  uTvl: BigNumber
  liquidityShare: number
  earned: BigNumber
  apr: number
  rewardRate: BigNumber
  weeklyRewardRate: BigNumber
  uWeeklyRewardRate: BigNumber
}

interface InfoResponse {
  result?: StakeResult
  isLoading: boolean
  isFetching: boolean
}

const useGetStakeInfo = ({
  provider, chainId, account, stakingAddress = ethers.constants.AddressZero,
}: InfoProps): InfoResponse => {
  const chain = getChainData(chainId)

  const { response, isLoading, isFetching } = useMulticall2({
    provider,
    address: chain?.multicall2address || ethers.constants.AddressZero,
    params: [
      {
        target: stakingAddress,
        callData: getSighash('rewardRate()'),
      },
      {
        target: stakingAddress,
        callData: getSighash('lastUpdateTime()'),
      },
      {
        target: stakingAddress,
        callData: getSighash('periodFinish()'),
      },
      {
        target: stakingAddress,
        callData: getSighash('totalSupply()'),
      },
      {
        target: stakingAddress,
        callData: stakingRewardsInterface.encodeFunctionData('balanceOf(address)', [account]),
      },
      {
        target: stakingAddress,
        callData: stakingRewardsInterface.encodeFunctionData('earned(address)', [account]),
      },
    ],
  })

  if (response) {
    const [
      rewardRate,
      lastUpdateTime,
      periodFinish,
      totalSupply,
      currentStakingBalance,
      earned,
    ] = response
      .map((result) => BigNumber.from(result))

    const divider = parseUnits('1', 5)

    const distributionPeriodDays = Math.round((periodFinish.sub(lastUpdateTime)).toNumber() / (3600 * 24))
    const totalRewards = Math.round(+formatUnits(rewardRate, 18) * 3600 * 24 * distributionPeriodDays)

    const tvl = +formatUnits(totalSupply, 18)

    const liquidityShare = totalSupply.gt(0)
      ? +((currentStakingBalance.mul(divider.toNumber()).div(totalSupply).toNumber() / 1000).toFixed(2))
      : 0

    const apr = +(((totalRewards * 365 * 100) / (tvl * distributionPeriodDays)).toFixed(2)) || Infinity

    const weeklyRewardRate = rewardRate.mul(BigNumber.from(3600 * 24 * 7))

    const uWeeklyRewardRate = weeklyRewardRate
      .div(BigNumber.from(100))
      .mul(BigNumber.from(Math.round(liquidityShare)))

    return {
      isLoading,
      isFetching,
      result: {
        tvl: totalSupply,
        uTvl: currentStakingBalance,
        liquidityShare,
        earned,
        apr,
        rewardRate,
        weeklyRewardRate,
        uWeeklyRewardRate,
      },
    }
  }

  return {
    isLoading,
    isFetching,
  }
}

export default useGetStakeInfo
