import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import { useFetchIDOPools } from '../../../hooks/launchpad'
import { PageBox } from '../../boxes'
import { IDOParticipatePaper, IDOInfoPaper } from '../../papers'

const IDO = (): JSX.Element => {
  const { token0: address } = useParams()
  const { pools, isLoading } = useFetchIDOPools({
    address,
    filter: 'skip',
  })

  return (
    <PageBox
      sx={{
        margin: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 1150,
        alignItems: 'center',
      }}
    >
      <Grid
        container
        spacing={4}
      >
        {isLoading && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="secondary" />
          </Grid>
        )}
        {!isLoading && pools.length === 1 && (
          <>
            <Grid
              item
              lg={4}
              md={5}
              sm={6}
              xs={12}
            >
              <IDOParticipatePaper pool={pools[0]} />
            </Grid>
            <Grid
              item
              lg={8}
              md={7}
              sm={6}
              xs={12}
            >
              <IDOInfoPaper pool={pools[0]} />
            </Grid>
          </>
        )}
      </Grid>
    </PageBox>
  )
}

export default IDO
