import React, { useMemo } from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { useAppSelector } from '../../../app/hooks'

import { WarningPaper } from '../../papers'
import { PageBox, StakeFormBox, StakeInfoBox } from '../../boxes'
import { getGovernanceInfo } from '../../../helpers/utilities'
import { DEFAULT_STAKING_TOKEN } from '../../../helpers/constants'

const Stake = (): JSX.Element => {
  const { chainId } = useAppSelector((state) => state.wallet)

  const { currency: govCurrency, staker: govContract } = useMemo(() => getGovernanceInfo(chainId), [chainId])

  const govSymbol = govCurrency?.symbol || DEFAULT_STAKING_TOKEN

  return (
    <PageBox>
      <WarningPaper>
        <Grid container>
          <Grid item xs={1}>
            <InfoOutlinedIcon sx={{ width: 0.5, height: 0.5 }} />
          </Grid>
          <Grid item xs={11}>
            <Typography
              align="left"
              variant="subtitle2"
            >
              {`${govSymbol} Staking`}
            </Typography>
            <Typography
              align="left"
            >
              {`Stake ${govSymbol} to automatically participate in `
                + `governance and earn ${govSymbol} rewards.`}
            </Typography>
            {/* <Link href="/#" style={{ float: 'left' }} color="inherit">
              Read more about providing liquidity
            </Link> */}
          </Grid>
        </Grid>
      </WarningPaper>
      {govCurrency && <StakeInfoBox stakeCurrency={govCurrency} />}
      <StakeFormBox
        stakeCurrency={govCurrency}
        farmCurrency={govContract}
      />
    </PageBox>
  )
}

export default Stake
