import { useMemo } from 'react'
import * as Sentry from '@sentry/react'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { BridgeDirectionName, BridgeStatus } from '../../features/bridge/bridgeSlice'
import { CrossChainTransferStatus, initializeTx } from '../../features/wallet/transactionSlice'
import { ModalType, openModal } from '../../features/modal/modalSlice'
import { ColorizedButton } from './ColorizedButton'

import useBridgeCheckAllowance from '../../hooks/useBridgeCheckAllowance'
import useERC20Approve from '../../hooks/useERC20Approve'

interface ButtonProps {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

export const BridgeTransferButton = (props: ButtonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const chainId = useAppSelector((state) => state.wallet.chainId)
  const account = useAppSelector((state) => state.wallet.account)
  const value = useAppSelector((state) => state.bridge.directions[BridgeDirectionName.SOURCE].value)
  const sourceCurrency = useAppSelector((state) => state.bridge.directions[BridgeDirectionName.SOURCE].currency)
  const targetCurrency = useAppSelector((state) => state.bridge.directions[BridgeDirectionName.TARGET].currency)
  const bridgeStatus = useAppSelector((state) => state.bridge.status)

  const { library: provider } = useWeb3React<Web3Provider>(BridgeDirectionName.SOURCE)
  const { library: metaProvider } = useWeb3React<Web3Provider>()

  const {
    allowance, isRequired, isSkipped, isLoading,
  } = useBridgeCheckAllowance({
    provider,
    currency: sourceCurrency,
    account,
  })

  const isApproved = (allowance && value) ? allowance.gte(value) : false

  const sourceBridgeAddress = sourceCurrency?.getBridged(targetCurrency?.chainId)?.originBridgeAddress
  const { approve, isLoading: isApproveLoading } = useERC20Approve({
    provider: metaProvider,
    chainId,
    account,
    contract: sourceCurrency?.address,
    addressToApprove: sourceBridgeAddress,
  })

  const onApprove = async () => {
    if (
      !sourceCurrency
      || !targetCurrency
      || !value
    ) {
      Sentry.captureMessage('Transfer button triggered, but not value and currency for this')
      return
    }

    await approve(sourceCurrency.chainId)
  }

  const onCrossChainTransfer = () => {
    if (
      !sourceCurrency
      || !targetCurrency
      || !value
    ) {
      Sentry.captureMessage('Transfer button triggered, but not value and currency for this')
      return
    }

    const targetBridgeAddress = targetCurrency.getBridged(sourceCurrency.chainId)?.originBridgeAddress
    if (
      !sourceBridgeAddress
      || !targetBridgeAddress
    ) {
      Sentry.captureMessage('Bridge not found during transfer click')
      return
    }

    dispatch(initializeTx({
      value,
      from: account,
      to: account,
      status: CrossChainTransferStatus.DEPOSIT_INITIATION,
      deposit: {
        currency: sourceCurrency,
        bridgeAddress: sourceBridgeAddress,
      },
      withdraw: {
        currency: targetCurrency,
        bridgeAddress: targetBridgeAddress,
      },
    }))
    dispatch(openModal(ModalType.BRIDGE_TRANSFER))
  }

  const { disabled, text } = useMemo(() => {
    switch (bridgeStatus) {
      case BridgeStatus.REQURED_AMOUNT:
        return {
          disabled: true,
          text: 'Enter an amount',
        }
      case BridgeStatus.NOT_ENOUGH_BALANCE:
        return {
          disabled: true,
          text: 'Not enough balance',
        }
      case BridgeStatus.UNSUPPORTED_DIRECTION:
        return {
          disabled: true,
          text: 'Unsupported direction for bridge',
        }
      case BridgeStatus.READY:
        return {
          disabled: false,
          text: 'Transfer',
        }
      default:
        return {
          disabled: true,
          text: '-',
        }
    }
  }, [bridgeStatus])

  if (disabled) {
    return (
      <ColorizedButton
        fullWidth
        onClick={onCrossChainTransfer}
        disabled={disabled}
        {...props}
      >
        {text}
      </ColorizedButton>
    )
  }

  if (!isSkipped && isRequired && !isApproved) {
    return (
      <ColorizedButton
        loading={isLoading || isApproveLoading}
        fullWidth
        onClick={onApprove}
        {...props}
      >
        Approve
      </ColorizedButton>
    )
  }

  return (
    <ColorizedButton
      loading={isLoading}
      fullWidth
      onClick={onCrossChainTransfer}
      disabled={disabled}
      {...props}
    >
      {text}
    </ColorizedButton>
  )
}
