import { useState } from 'react'
import { ethers, BigNumber } from 'ethers'

import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch } from '../../app/hooks'
import useFetchMetamaskAPI from '../useFetchMetamaskAPI'
import IStakingRewardsABI from '../../abi/IStakingRewards.abi.json'

import { StakingDirection } from '..'
import { initializeTx, LocalTxStatus } from '../../features/wallet/transactionSlice'
import { getChainData } from '../../helpers/utilities'

interface StakeData {
  provider?: Web3Provider
  chainId: number
  to: string
  contract?: string
}

interface StakeParams {
  action: StakingDirection
  amount?: BigNumber
  isMax?: boolean
}

interface StakeAction {
  execute(params: StakeParams): Promise<string | undefined>
  isLoading: boolean
}

const useStake = ({
  provider, chainId, to, contract,
}: StakeData): StakeAction => {
  const dispatch = useAppDispatch()

  const chain = getChainData(chainId)
  const { callContract } = useFetchMetamaskAPI(provider)
  const [isLocked, setLock] = useState(false)

  const execute = async ({ action, amount, isMax = false }: StakeParams): Promise<string | undefined> => {
    if (!contract || isLocked || !chain) return undefined

    let [method, params]: [string, unknown[]] = ['', []]
    const value = ethers.constants.Zero

    if (action === 'getReward') {
      method = 'getReward'
    } else if (action === 'getRewards') {
      method = 'getRewards'
    } else if (action === 'stake') {
      method = 'stake'
      params = [amount]
    } else if (isMax === true) {
      method = 'exit'
    } else {
      method = 'withdraw'
      params = [amount]
    }

    if (!method) return undefined

    setLock(true)

    const txHash = await callContract({
      from: to,
      value,
      contract: {
        address: contract,
        abi: IStakingRewardsABI,
        method,
        params,
      },
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: contract,
        value,
        status: LocalTxStatus.PENDING,
        abi: IStakingRewardsABI,
        method,
        params,
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    execute,
    isLoading: isLocked,
  }
}

export default useStake
