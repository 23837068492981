import { useState, useMemo, useEffect } from 'react'
import { ethers } from 'ethers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import useFetchMetamaskAPI from '../useFetchMetamaskAPI'
import ILaunchpadIDOABI from '../../abi/ILaunchpadIDO.abi.json'
import {
  getLocalTxByFilter,
  initializeTx, isBridgeTx, LocalTxStatus, updateLocalTxByHash,
} from '../../features/wallet/transactionSlice'
import { HookProvider, useWatchTx } from '..'

interface ClaimData {
  provider?: HookProvider
  chainId: number
  to: string
  contract?: string
}

interface ClaimRequest {
  account: string
  idx: number
}

interface ClaimAction {
  claim(params: ClaimRequest): Promise<string | undefined>
  isLoading: boolean
}

const useClaimIDO = ({
  provider, chainId, to, contract,
}: ClaimData): ClaimAction => {
  const dispatch = useAppDispatch()

  const { callContract } = useFetchMetamaskAPI(provider)
  const [isLocked, setLock] = useState(false)
  const [currentTxHash, setTxHash] = useState<string | undefined>()

  const txFilter = useMemo(() => ([{
    from: to,
    to: contract,
    status: LocalTxStatus.PENDING,
    method: 'claim(address,uint256)',
  }]), [to, contract])
  const currentState = useAppSelector(getLocalTxByFilter(txFilter))

  // hook to check for current selected contract, in case of another, reset
  useEffect(() => {
    if (!contract) return

    if (!currentState && currentTxHash) {
      setTxHash(undefined)
    }
    if (currentState && currentState.txHash && currentTxHash !== currentState.txHash) {
      setTxHash(currentState.txHash)
    }
  }, [currentState, currentTxHash, contract])

  useWatchTx({
    provider,
    txHash: isBridgeTx(currentState) ? undefined : currentState?.txHash,
    confirmations: isBridgeTx(currentState) ? undefined : currentState?.confirmations,
    onSuccess: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.SUCCESS }))
      setTxHash(undefined)
    },
    onError: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.FAIL }))
      setTxHash(undefined)
    },
  })

  const claim = async ({
    account, idx,
  }: ClaimRequest): Promise<string | undefined> => {
    if (isLocked || !contract) return undefined

    const method = 'claim(address,uint256)'
    const params = [account, idx]
    const value = undefined

    setLock(true)

    const txHash = await callContract({
      from: to,
      value,
      contract: {
        address: contract,
        abi: ILaunchpadIDOABI,
        method,
        params,
      },
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: contract,
        value: value || ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi: ILaunchpadIDOABI,
        method,
        params,
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    claim,
    isLoading: isLocked || currentState?.status === LocalTxStatus.PENDING,
  }
}

export default useClaimIDO
