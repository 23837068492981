import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer, Transform, createTransform } from 'redux-persist'
import { v4 as uuidv4 } from 'uuid'
import { BigNumber } from 'ethers'
import { encode, decode } from '@msgpack/msgpack'

import { Currency } from '../../entities'
import { SignedData } from '../api/cosignerApiSlice'
import { RootState } from '../../app/store'
import { createStorage, getNowTs } from '../../helpers/utilities'

export enum CrossChainTransferStatus {
  DEPOSIT_INITIATION = 0,
  COSIGNER_APPROVAL = 1,
  WITHDRAW_INITIATION = 2,
  DONE = 3,
}

export enum LocalTxStatus {
  PENDING = -1,
  FAIL = 0,
  SUCCESS = 1,
}

export const CrossChainTransferStatusList = Object.values(CrossChainTransferStatus).filter(
  (value) => typeof value !== 'string',
) as CrossChainTransferStatus[]

interface BaseLocalTx {
  // local id
  id: string
  // wallet sender
  from: string
  // in case we will have support for destination address, let's use it rightaway
  to: string
  // amount to send
  value: BigNumber
  // time of create
  createdAt: number
  // time of update
  updatedAt: number
}

interface DirectionProcessState {
  txHash?: string
  confirmations?: number
  currency: Currency
  bridgeAddress: string
}

export interface CrossChainTx extends BaseLocalTx {
  // status of transaction
  status: CrossChainTransferStatus
  // deposit info about chain, currency and rest tx staff
  deposit: DirectionProcessState
  // withdraw info about chain, currency and rest tx staff
  withdraw: DirectionProcessState
  // API data from cosigner service
  signedData?: SignedData
}

export interface LocalTx extends BaseLocalTx {
  txHash?: string
  confirmations?: number
  status: LocalTxStatus
  chainId: number
  abi?: unknown
  method: string
  params: Array<unknown>
}

interface InitTxPayload {
  value: BigNumber
  from: string
  to: string
  store?: {
    update: boolean
  }
}

interface InitCrossChainTx extends InitTxPayload {
  status: CrossChainTransferStatus
  deposit: DirectionProcessState
  withdraw: DirectionProcessState
}

interface InitLocalTx extends InitTxPayload {
  txHash?: string
  chainId: number
  status: LocalTxStatus
  abi?: unknown
  method: string
  params: Array<unknown>
}

interface UpdateData {
  txHash?: string
  confirmations?: number
  status?: CrossChainTransferStatus | LocalTxStatus
  force?: boolean
}

interface TransactionState {
  current?: LocalTx | CrossChainTx
  txs: Array<LocalTx | CrossChainTx>
}

const initialState: TransactionState = {
  current: undefined,
  txs: [],
}

export const isBridgeTx = (object: unknown): object is CrossChainTx => (
  (typeof object !== 'undefined') ? Object.prototype.hasOwnProperty.call(object, 'deposit') : false
)

const stateUpdateCurrent = (state: TransactionState) => {
  if (state.current) {
    const current = { ...state.current }
    current.updatedAt = getNowTs()
    const index = state.txs.findIndex((tx) => tx.id === current.id)
    if (index !== -1) {
      state.txs[index] = current
    } else {
      state.txs.push(current)
    }
  }
}

const generateInitTxPayload = () => ({
  id: uuidv4(),
  createdAt: getNowTs(),
  updatedAt: getNowTs(),
})

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    initializeTx: (state, action: PayloadAction<InitCrossChainTx | InitLocalTx>) => {
      state.current = {
        ...generateInitTxPayload(),
        ...action.payload,
      }
      if (action.payload.store && action.payload.store.update) {
        stateUpdateCurrent(state)
      }
    },
    selectCurrent: (state, action: PayloadAction<string>) => {
      const match = state.txs.find((tx) => tx.id === action.payload)
      if (
        (match && state.current && match.id !== state.current.id)
        || (match && !state.current)
      ) {
        state.current = match
      }
    },
    clearCurrent: (state) => {
      state.current = undefined
    },
    clearAllTxs: (state) => {
      state.current = undefined
      state.txs = []
    },
    updateCurrent: stateUpdateCurrent,
    updateStatus: (state, action: PayloadAction<CrossChainTransferStatus>) => {
      if (!state.current || !isBridgeTx(state.current)) return
      state.current.status = action.payload
      stateUpdateCurrent(state)
    },
    updateSignedData: (state, action: PayloadAction<SignedData>) => {
      if (!state.current || !isBridgeTx(state.current)) return
      state.current.signedData = action.payload
      stateUpdateCurrent(state)
    },
    resetDepositTx: (state) => {
      if (!state.current || !isBridgeTx(state.current)) return
      state.current.deposit.txHash = undefined
      stateUpdateCurrent(state)
    },
    resetWithdrawTx: (state) => {
      if (!state.current || !isBridgeTx(state.current)) return
      state.current.withdraw.txHash = undefined
      stateUpdateCurrent(state)
    },
    updateDepositData: (state, action: PayloadAction<UpdateData>) => {
      if (!state.current || !isBridgeTx(state.current)) return
      if (action.payload.txHash) {
        state.current.deposit.txHash = action.payload.txHash
      }
      if (action.payload.confirmations) {
        state.current.deposit.confirmations = action.payload.confirmations
      }
      if (action.payload.status) {
        state.current.status = action.payload.status as CrossChainTransferStatus
      }
      stateUpdateCurrent(state)
    },
    updateWithdrawData: (state, action: PayloadAction<UpdateData>) => {
      if (!state.current || !isBridgeTx(state.current)) return
      if (action.payload.txHash) {
        state.current.withdraw.txHash = action.payload.txHash
      }
      if (action.payload.confirmations) {
        state.current.withdraw.confirmations = action.payload.confirmations
      }
      if (action.payload.status) {
        state.current.status = action.payload.status as CrossChainTransferStatus
      }
      stateUpdateCurrent(state)
    },
    updateLocalTxByHash: (state, action: PayloadAction<UpdateData>) => {
      const index = state.txs
        .findIndex((tx) => (
          isBridgeTx(tx)
            ? false
            : tx.txHash === action.payload.txHash
        ))
      if (index !== -1) {
        const tx = state.txs[index] as LocalTx
        tx.status = action.payload.status as (LocalTxStatus) | LocalTxStatus.PENDING
        tx.confirmations = action.payload.confirmations
        state.txs[index] = tx
      }
    },
  },
})

export const getAccountTransactions = (state: RootState): (CrossChainTx | LocalTx)[] => (
  state.transaction.txs
    .slice(0)
    .sort((tx1, tx2) => tx2.createdAt - tx1.createdAt)
    .filter((tx) => tx.from === state.wallet.account)
)

export const getUnfinishedTxCount = (state: RootState): number => (
  state.transaction.txs
    .slice(0)
    .filter((tx) => (
      tx.from === state.wallet.account
      && (isBridgeTx(tx)
        ? tx.status !== CrossChainTransferStatus.DONE
        : tx.status === LocalTxStatus.PENDING)
    ))
    .length
)

export const getUnfinishedBridgeTx = (id: string) => (state: RootState): CrossChainTx | undefined => (
  state.transaction.txs
    .filter((tx) => isBridgeTx(tx) && tx.status !== CrossChainTransferStatus.DONE)
    .find((tx) => tx.id === id) as unknown as (CrossChainTx | undefined)
)

export const getCurrentBridgeTx = (state: RootState): CrossChainTx | undefined => (
  isBridgeTx(state.transaction.current) ? state.transaction.current : undefined
)

export const getTxByHash = (txHash?: string) => (state: RootState): CrossChainTx | LocalTx | undefined => (
  state.transaction.txs
    .find((tx) => (
      isBridgeTx(tx)
        ? tx.withdraw.txHash === txHash || tx.deposit.txHash === txHash
        : tx.txHash === txHash
    ))
)

interface TxFilter {
  from?: string
  to?: string
  status?: LocalTxStatus
  method?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
type FilterTx = Object & {
  [key: string]: unknown
}

const filterSearch = (tx: LocalTx, filters: TxFilter[]): boolean => {
  let reduceState: boolean | undefined
  filters.forEach((filter) => {
    Object.keys(filter).forEach((key) => {
      const resTx = tx as unknown as FilterTx
      const resFilter = filter as unknown as FilterTx
      // eslint-disable-next-line no-prototype-builtins
      if (resFilter.hasOwnProperty(key)) {
        if (typeof reduceState === 'undefined') {
          reduceState = resTx[key] === resFilter[key]
        } else {
          reduceState = reduceState && resTx[key] === resFilter[key]
        }
      }
    })
  })
  return (typeof reduceState === 'undefined') ? false : reduceState
}

export const getLocalTxByFilter = (filters: TxFilter[]) => (state: RootState): LocalTx | undefined => (
  state.transaction.txs.find((tx) => !isBridgeTx(tx) && filterSearch(tx, filters)) as (LocalTx | undefined)
)

export const {
  initializeTx,
  selectCurrent,
  updateCurrent,
  clearCurrent,
  clearAllTxs,
  updateStatus,
  resetDepositTx,
  resetWithdrawTx,
  updateDepositData,
  updateWithdrawData,
  updateSignedData,
  updateLocalTxByHash,
} = transactionSlice.actions

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const txMsgPackCompressor: Transform<any, any, TransactionState, TransactionState> = createTransform(
  (state) => {
    const encoded = encode(state)
    return Array.from(encoded)
  },
  (state) => {
    if (!Array.isArray(state)) {
      console.error('txMsgPackCompressor: expected outbound state to be an array')
      return state
    }
    try {
      const decoded = decode(state)
      return decoded
    } catch (err) {
      console.error('txMsgPackCompressor: error while decompressing state', err)
    }
    return []
  },
  {
    whitelist: ['txs'],
  },
)

export default persistReducer({
  key: transactionSlice.name,
  version: 2,
  storage: createStorage('syndicateDB'),
  blacklist: ['current'],
  transforms: [txMsgPackCompressor],
}, transactionSlice.reducer)
