import { constants, BigNumber } from 'ethers'

import IMoonbaPairABI from '../../abi/IMoonbaPair.abi.json'
import { useWeb3RequestQuery } from '../../features/api/web3ApiSlice'
import { HookProvider } from '../types'

interface GetReservesData {
  provider?: HookProvider
  pair?: string
}

interface Reserves {
  reserve0: BigNumber
  reserve1: BigNumber
  blockTimestampLast: number
}

interface Response {
  reserves?: Reserves
  isFetching: boolean
  isLoading: boolean
}

const useGetReserves = ({
  provider, pair,
}: GetReservesData): Response => {
  const skip = (
    pair === constants.AddressZero
    || typeof pair === 'undefined'
    || !provider
  )

  const { data: reservesData, ...reservesQueryData } = useWeb3RequestQuery({
    provider,
    request: {
      address: pair,
      abi: IMoonbaPairABI,
      method: 'getReserves',
    },
  }, {
    skip,
    pollingInterval: 30_000,
  })

  const reserves = reservesData ? reservesData as Reserves : undefined

  return {
    reserves,
    ...reservesQueryData,
  }
}

export default useGetReserves
