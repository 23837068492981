import { memo } from 'react'

import { styled, SxProps, Theme } from '@mui/material/styles'
import { Icon } from '@mui/material'

const Img = styled('img')(() => ({
  display: 'flex',
  height: 'inherit',
  width: 'inherit',
}))

export interface ImgIconProps {
  src?: string
  alt?: string
  height?: number | string
  width?: number | string
  sx?: SxProps<Theme>
}

const ImgIcon = memo(({
  width, height, alt = 'icon', ...restImgProps
}: ImgIconProps): JSX.Element => (
  <Icon sx={{
    width,
    height,
  }}
  >
    <Img alt={alt} {...restImgProps} />
  </Icon>
))

ImgIcon.displayName = 'ImgIcon'

export default ImgIcon
