import { useState } from 'react'
import { ethers, BigNumber } from 'ethers'

import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch } from '../../app/hooks'
import useFetchMetamaskAPI from '../useFetchMetamaskAPI'
import ILaunchpadIDOABI from '../../abi/ILaunchpadIDO.abi.json'
import {
  initializeTx, LocalTxStatus,
} from '../../features/wallet/transactionSlice'
import { Currency } from '../../entities'

interface BuyData {
  provider?: Web3Provider
  chainId: number
  to: string
}

interface BuyRequest {
  currency: Currency
  amount: BigNumber
  idoAddress: string
}

interface BuyAction {
  buyTokens(params: BuyRequest): Promise<string | undefined>
  isLoading: boolean
}

const useParticipateIDO = ({
  provider, chainId, to,
}: BuyData): BuyAction => {
  const dispatch = useAppDispatch()

  const { callContract } = useFetchMetamaskAPI(provider)
  const [isLocked, setLock] = useState(false)

  const buyTokens = async ({
    currency, amount, idoAddress,
  }: BuyRequest): Promise<string | undefined> => {
    if (isLocked) return undefined

    let [method, params, value]: [string, unknown[], BigNumber | undefined] = ['', [], undefined]

    if (currency.address === ethers.constants.AddressZero) {
      method = 'buyTokens()'
      params = []
      value = amount
    } else {
      method = 'buyTokens(uint256)'
      params = [amount]
    }

    setLock(true)

    const txHash = await callContract({
      from: to,
      value,
      contract: {
        address: idoAddress,
        abi: ILaunchpadIDOABI,
        method,
        params,
      },
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: idoAddress,
        value: value || ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi: ILaunchpadIDOABI,
        method,
        params,
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    buyTokens,
    isLoading: isLocked,
  }
}

export default useParticipateIDO
