import Link from '@mui/material/Link'
import { SocialIcon } from '../icons'

const SocialLink: React.FC<{ url: string }> = ({ url }) => (
  <Link
    target="_blank"
    rel="noopener noreferrer"
    href={url}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
    mr={1}
    mb={1}
  >
    <SocialIcon url={url} />
  </Link>
)

export default SocialLink
