import {
  Ether, MATIC, BNB, OLT, OKT,
} from '../entities'
import { ChainData } from './types'

export const supportedChains: ChainData[] = [
  // mainnets
  {
    name: 'Ethereum',
    shortName: 'eth',
    chain: 'ETH',
    network: 'ethereum',
    chainId: 1,
    networkId: '1',
    rpcURL: 'https://nd-617-613-967.p2pify.com/f0cfdcd380d171c1c016b6c826efe421',
    explorerURL: 'https://etherscan.io',
    isTest: false,
    nativeCurrency: new Ether(1),
    defaultTargetChainId: 311752642,
    bridgeAddress: '0x337882C0f263D457704Bd52c5741d43F71F316c0',
  },
  {
    name: 'OneLedger',
    shortName: 'olt',
    chain: 'OLT',
    network: 'oneledger',
    chainId: 311752642,
    networkId: '311752642',
    rpcURL: 'https://mainnet-rpc.oneledger.network',
    explorerURL: 'https://mainnet-explorer.oneledger.network',
    isTest: false,
    nativeCurrency: new OLT(311752642),
    defaultTargetChainId: 1,
    bridgeAddress: '0xE9cB771A919ABe70612af5639E6b9c441688C600',
    dex: {
      factoryAddress: '0xf043718b9a753C88cd73245A39dC11Ae034B0946',
      routerAddress: '0x7bD84CD9088bFfF318Aa91E2dEB6c5399A93E442',
    },
    multicall2address: '0xf25897E97bd3Df8F2b02d94f75A5066A8798fEF1',
    graphNodeURL: 'https://mainnet-graph.oneledger.network',
  },
  {
    name: 'BSC',
    shortName: 'bsc',
    chain: 'BSC',
    network: 'bsc',
    chainId: 56,
    networkId: '56',
    rpcURL: 'https://nd-751-886-913.p2pify.com/84edd4f8b695abec8585dd1a64e09748',
    explorerURL: 'https://bscscan.com',
    isTest: false,
    nativeCurrency: new BNB(56),
    defaultTargetChainId: 311752642,
    bridgeAddress: '0x309bdec69FB76861192bFD56dbEC1212a4D3B191',
  },
  {
    name: 'Polygon',
    shortName: 'matic',
    chain: 'MATIC',
    network: 'matic',
    chainId: 137,
    networkId: '137',
    rpcURL: 'https://nd-653-363-719.p2pify.com/c7bfbf8b0b51eed87b20002e3e32233d',
    explorerURL: 'https://polygonscan.com',
    isTest: false,
    nativeCurrency: new MATIC(137),
    defaultTargetChainId: 311752642,
    bridgeAddress: '0x51Bf2FeAf9F72a546e86Bb8ce44705A55Ca881CA',
  },
  {
    name: 'OKC',
    shortName: 'okc',
    chain: 'OKC',
    network: 'okc',
    chainId: 66,
    networkId: '66',
    rpcURL: 'https://exchainrpc.okex.org',
    explorerURL: 'https://www.oklink.com/okc',
    isTest: false,
    nativeCurrency: new OKT(66),
    defaultTargetChainId: 311752642,
    bridgeAddress: '0x8d5E6EE117cBa6bf130cc31d21F98200897E4DF8',
  },
  // testnets
  // {
  //   name: 'Ropsten',
  //   shortName: 'rop',
  //   chain: 'ETH',
  //   network: 'ropsten',
  //   chainId: 3,
  //   networkId: '3',
  //   rpcURL: 'https://speedy-nodes-nyc.moralis.io/e043a7b3562909a3d7c7d329/eth/ropsten',
  //   explorerURL: 'https://ropsten.etherscan.io',
  //   isTest: true,
  //   nativeCurrency: new Ether(3),
  //   defaultTargetChainId: 4216137055,
  //   bridgeAddress: '0x319c42009f309e38501b52F4DBd317B927450883',
  // },
  // {
  //   name: 'Frankenstein',
  //   shortName: 'frank',
  //   chain: 'OLT',
  //   network: 'frankenstein',
  //   chainId: 4216137055,
  //   networkId: '4216137055',
  //   rpcURL: 'https://frankenstein-rpc.oneledger.network',
  //   explorerURL: 'https://frankenstein-explorer.oneledger.network',
  //   isTest: true,
  //   nativeCurrency: new OLT(4216137055),
  //   defaultTargetChainId: 3,
  //   bridgeAddress: '0x83Bc71C069774356469D1c79b43E346bf160757b',
  //   dex: {
  //     factoryAddress: '0xB4272b228bc4235d79cfa0Fd1740dd62a9DE08D8',
  //     routerAddress: '0xBaf873BF0902D54588C3ebdB80BE34DbB50E852e',
  //   },
  //   governance: {
  //     tokenAddress: '0xf149772d815a49BDD7C85580ebc250B6c7c90bCa',
  //     stakingAddress: '0xf798d4C7DD0E6e3643665aE6b77DACaeCeF066C9',
  //   },
  //   multicall2address: '0xa389A2e4F3c68b4f903c63D63b9300700D78Fb1d',
  //   graphNodeURL: 'https://frankenstein-graph.oneledger.network',
  // },
  // {
  //   name: 'BSC Testnet',
  //   shortName: 'bsc_testnet',
  //   chain: 'BSC',
  //   network: 'bsc_testnet',
  //   chainId: 97,
  //   networkId: '97',
  //   rpcURL: 'https://speedy-nodes-nyc.moralis.io/e043a7b3562909a3d7c7d329/bsc/testnet',
  //   explorerURL: 'https://testnet.bscscan.com',
  //   isTest: true,
  //   nativeCurrency: new BNB(97),
  //   defaultTargetChainId: 4216137055,
  //   bridgeAddress: '0x3BE0E4a86545F123d8b46eECecCc205BbE57Eb99',
  // },
  // {
  //   name: 'Mumbai',
  //   shortName: 'mumbai',
  //   chain: 'MATIC',
  //   network: 'mumbai',
  //   chainId: 80001,
  //   networkId: '80001',
  //   rpcURL: 'https://speedy-nodes-nyc.moralis.io/e043a7b3562909a3d7c7d329/polygon/mumbai',
  //   explorerURL: 'https://mumbai.polygonscan.com',
  //   isTest: true,
  //   nativeCurrency: new MATIC(80001),
  //   defaultTargetChainId: 4216137055,
  //   bridgeAddress: '0x99d4Ab6632E703137b59a92169850e554cAAdEFF',
  // },
]
