import { ethers } from 'ethers'
import { IDOClaim } from '.'
import { HookProvider } from '..'
import { getChainData } from '../../helpers/utilities'
import { useMulticall2 } from '../multicall'
import { launchpadInterface } from './utils'

interface FetchClaimData {
  provider?: HookProvider
  chainId: number
  contract: string
  account: string
}

interface FetchClaimResult {
  data?: {
    claims: IDOClaim[]
  }
  isLoading: boolean
  isFetching: boolean
}

const useFetchIDOInfo = ({
  provider, chainId, contract, account,
}: FetchClaimData): FetchClaimResult => {
  const chain = getChainData(chainId)

  const params = [
    {
      target: contract,
      callData: launchpadInterface.encodeFunctionData('getClaims(address)', [account]),
    },
  ]

  const { response, isLoading, isFetching } = useMulticall2({
    provider,
    address: chain?.multicall2address || ethers.constants.AddressZero,
    params,
  })

  const result: FetchClaimResult = {
    isFetching,
    isLoading,
  }

  if (response?.length === params.length) {
    const unparsedClaims = (response[0] === '0x')
      ? [[]]
      : launchpadInterface.decodeFunctionResult('getClaims(address)', response[0])
    const count = unparsedClaims[0].length

    const claims = Array.from({ length: count }, (_, i) => ({
      idx: i,
      time: unparsedClaims[0][i].toNumber(),
      percent: unparsedClaims[1][i],
      amount: unparsedClaims[2][i],
      isClaimable: unparsedClaims[3][i],
      claimedAmount: unparsedClaims[4][i],
    })).sort((x, y) => x.time - y.time)
    result.data = {
      claims,
    }
  }

  return result
}

export default useFetchIDOInfo
