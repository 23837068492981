import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { ImgIcon } from '../icons'
import {
  getDumbLogoURI, getPrettyDate, prettyFormat,
} from '../../helpers/utilities'
import { SocialLink } from '../links'
import { IDOProgressBar } from '../bars'
import { IDOPool } from '../../hooks/launchpad'
import { radialConstructor } from './utils'

interface IDOSelectPaperProps {
  pool: IDOPool
}

const IDOSelectPaper = ({ pool }: IDOSelectPaperProps): JSX.Element => {
  const navigate = useNavigate()

  const {
    description,
    socials,
    sellToken,
    buyToken,
    address,
    timeline,
    hasKYC,
    decimals,
  } = pool

  const {
    totalRaise,
    hardCap,
    rate,
    reversePoolRate,
    tokensSold,
  } = pool.amounts

  return (
    <Paper
      elevation={4}
      sx={[{
        transition: 'transform',
        transitionDuration: '.15s!important',
        transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)!important',
        '&:hover': {
          cursor: 'pointer',
          transform: 'translateY(-0.5rem)!important',
        },
      }, (theme) => ({
        background: (theme.palette.mode === 'light')
          ? radialConstructor('#ffffff', theme.palette.primary.main)
          : radialConstructor(theme.palette.primary.light, theme.palette.primary.dark),
      })]}
      onClick={() => navigate(`/ido/${address}`)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'unset',
          justifyContent: 'space-between',
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            {buyToken.name}
          </Typography>
          <Typography variant="body2" sx={{ opacity: '.7' }}>{`$${buyToken.symbol}`}</Typography>
        </Box>
        <ImgIcon
          src={buyToken.logoURI || getDumbLogoURI()}
          height={48}
          width="auto"
          sx={(theme) => ({
            backgroundColor: (theme.palette.mode === 'light') ? 'white' : 'inherit',
            p: 0.5,
          })}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flext-start',
          flexWrap: 'wrap',
          width: '75hv',
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {socials.map((socialLink) => (
          <SocialLink key={`${socialLink.slice(0, 20)}`} url={socialLink} />
        ))}
      </Box>
      {description && (
        <Box
          sx={[
            {
              paddingTop: 0,
              padding: 2,
              height: 56,
            },
            (theme) => ({
              maskImage: `linear-gradient(${theme.palette.primary.main} 50%, transparent 100%)`,
            }),
          ]}
        >
          <Box
            sx={{
              opacity: '.7',
            }}
          >
            <Typography variant="body2">
              {description}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'unset',
          justifyContent: 'space-between',
          flexDirection: 'column',
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          pt={1}
        >
          <Typography variant="subtitle2">Total raise</Typography>
          <Typography variant="subtitle2" color="secondary">
            {`${sellToken?.symbol} ${prettyFormat(totalRaise, decimals, 0).toLocaleString()}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          pt={1}
        >
          <Typography variant="subtitle2">Starts</Typography>
          <Typography variant="subtitle2" color="secondary">
            {(timeline) ? getPrettyDate(timeline.startTime) : 'TBA'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          pt={1}
        >
          <Typography variant="subtitle2">Price</Typography>
          <Typography variant="subtitle2" color="secondary">
            {`1 ${buyToken.symbol} = ${prettyFormat(reversePoolRate, 6, 6)} ${sellToken.symbol}`}
          </Typography>
        </Box>
        <IDOProgressBar
          startTime={timeline.startTime}
          endTime={timeline.endTime}
          current={tokensSold}
          total={hardCap}
          rate={rate}
          decimals={decimals}
          buyToken={buyToken}
          sellToken={sellToken}
          color="secondary"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Typography variant="subtitle2">Listing time</Typography>
            <Typography variant="subtitle2" color="secondary">
              {timeline.listingTime ? getPrettyDate(timeline.listingTime) : 'TBA'}
            </Typography>
          </Box>
        </Box>
        {hasKYC && (
          <>
            <Divider sx={(theme) => ({
              backgroundColor: theme.palette.secondary.main,
              marginTop: 1,
              marginBottom: 1,
            })}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <Typography variant="subtitle2">This IDO requires KYC verification.</Typography>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  )
}

export default IDOSelectPaper
