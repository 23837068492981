import { useMemo } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { ApproveButton, ColorizedButton, SmartConnectorWrapper } from '.'
import { useAppSelector } from '../../app/hooks'
import { getSelectedDexToken, LiquidityTokenState, TokenIndexer } from '../../features/dex/dexSlice'

import { getPoolInfo } from '../../features/wallet/liquiditySlice'
import { getDexSettings } from '../../features/settings/settingsSlice'
import { calculateAmountWithSlippage } from '../../hooks/dex/utils'
import useRemoveLiquidity from '../../hooks/dex/useRemoveLiquidity'
import { useFetchDexCurrencies } from '../../hooks'
import { getChainData } from '../../helpers/utilities'

interface ButtonProps {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const RemoveLiquidityButton = (props: ButtonProps): JSX.Element => {
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const { deadline, slippage } = useAppSelector(getDexSettings)
  const { library: provider } = useWeb3React<Web3Provider>()
  const chain = getChainData(chainId)
  const contract = chain?.dex?.routerAddress

  const liquidityToken = useAppSelector(getSelectedDexToken(TokenIndexer.LIQUIDITY_TOKEN)) as LiquidityTokenState
  const pool = useAppSelector(getPoolInfo(liquidityToken.currency?.address))

  const { removeLiquidity, isLoading } = useRemoveLiquidity({
    provider,
    chainId,
    to: account,
    deadline,
  })

  const { getCurrency } = useFetchDexCurrencies()

  const handleClick = async () => {
    if (
      !liquidityToken.currency
      || !liquidityToken.approved
      || !liquidityToken.selectedAmount
      || !liquidityToken.selectedAmount0
      || !liquidityToken.selectedAmount1
      || !pool
    ) return

    const refetchedCurrency0 = getCurrency(chainId, pool.currency0.address)
    const refetchedCurrency1 = getCurrency(chainId, pool.currency1.address)

    if (!refetchedCurrency0 || !refetchedCurrency1) return

    await removeLiquidity({
      currency0: refetchedCurrency0,
      currency1: refetchedCurrency1,
      liquidity: liquidityToken.selectedAmount,
      amountMin0: calculateAmountWithSlippage(liquidityToken.selectedAmount0, slippage),
      amountMin1: calculateAmountWithSlippage(liquidityToken.selectedAmount1, slippage),
    })
    // if (txHash) {
    //   setSwapIndexer(undefined)
    //   dispatch(resetDexAmounts())
    // }
  }

  const { disabled, text } = useMemo(() => {
    // TODO: Add error handling
    if (!pool) {
      return {
        disabled: true,
        text: 'Pool not found',
      }
    }
    if (!liquidityToken.selectedAmount) {
      return {
        disabled: true,
        text: 'Enter an amount',
      }
    }
    if (liquidityToken.maxAmount?.lt(liquidityToken.selectedAmount)) {
      return {
        disabled: true,
        text: 'Insufficient amount',
      }
    }
    return {
      disabled: !liquidityToken.approved,
      text: 'Remove',
    }
  }, [pool, liquidityToken])

  return (
    <SmartConnectorWrapper variant="outlined" color="secondary" size="large">
      <ButtonGroup fullWidth sx={{ marginBottom: 1, '& button': { fontSize: 14 } }}>
        <ApproveButton
          indexer={TokenIndexer.LIQUIDITY_TOKEN}
          contract={contract}
          {...props}
        />
        <ColorizedButton
          fullWidth
          loading={isLoading}
          disabled={disabled}
          onClick={handleClick}
          {...props}
        >
          {text}
        </ColorizedButton>
      </ButtonGroup>
    </SmartConnectorWrapper>
  )
}

export default RemoveLiquidityButton
