import { useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { getProjectInfo } from '../../project'
import ImgIcon from '../icons/ImgIcon'

const LogoButton = (): JSX.Element => {
  const theme = useTheme()
  const logoInfo = getProjectInfo()
  return (
    <IconButton
      size="small"
      rel="noopener noreferrer"
      href={logoInfo.url}
      sx={{
        marginRight: 2,
        '&:hover': {
          backgroundColor: 'inherit',
        },
        '& img': {
          backgroundColor: (theme.palette.mode === 'light') ? 'white' : 'inherit',
          borderRadius: '50%',
          p: 0.5,
        },
      }}
    >
      <ImgIcon src={logoInfo.logo} width={48} height={48} />
    </IconButton>
  )
}

export default LogoButton
