export const DEX_SUBGRAPH_NAME = 'moonba-co/dex-subgraph-oneledger'

export interface Token {
  id: string
  symbol: string
  name: string
  decimals: string
  totalSupply: string
  tradeVolumeUSD: string
}

export interface Pair {
  id: string
  token0: Token
  token1: Token
  reserve0: string
  reserve1: string
  totalSupply: string
  reserveETH: string
  reserveUSD: string
  trackedReserveETH: string
  volumeUSD: string
}

export interface LiquidityPosition {
  id: string
  user: {
    id: string
  }
  pair: Pair
  liquidityTokenBalance: string
}

export const GET_PAIR = (id: string): string => `
  query {
    pair(id: "${id}") {
      id
      token0 {
        id
        symbol
        name
        decimals
        totalSupply
        tradeVolumeUSD
      }
      token1 {
        id
        symbol
        name
        decimals
        totalSupply
        tradeVolumeUSD
      }
      reserve0
      reserve1
      totalSupply
      reserveETH
      reserveUSD
      trackedReserveETH
      volumeUSD
    }
  }
`

export const GET_PAIRS = (ids: string[]): string => `
  query {
    pairs(where: { id_in: [${ids.map((id) => `"${id}"`).join(', ')}] }) {
      id
      token0 {
        id
        symbol
        name
        decimals
        totalSupply
        tradeVolumeUSD
      }
      token1 {
        id
        symbol
        name
        decimals
        totalSupply
        tradeVolumeUSD
      }
      reserve0
      reserve1
      totalSupply
      reserveETH
      reserveUSD
      trackedReserveETH
      volumeUSD
    }
  }
`

export const GET_LIQUIDITY_POSITION = (pair: string, user: string): string => `
  query {
    liquidityPosition(id: "${pair}-${user}") {
      id
      user {
        id
      }
      pair {
        id
        token0 {
          id
          symbol
          name
          decimals
        }
        token1 {
          id
          symbol
          name
          decimals
        }
        totalSupply
        reserveETH
        reserveUSD
      }
      liquidityTokenBalance
    }
  }
`
