import { useMemo } from 'react'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { HookProvider } from '.'
import { useAppSelector } from '../app/hooks'

const useWeb3Provider = (chainId?: number): HookProvider | undefined => {
  const { isAuthorized, chainId: selectedChainId } = useAppSelector((state) => state.wallet)
  const { library: defaultProvider } = useWeb3React<Web3Provider>()
  const { library: provider } = useWeb3React<Web3Provider>(chainId ? `MultipleConnector${chainId}` : undefined)
  return useMemo(() => {
    if (!chainId) {
      return undefined
    }
    if (!window.ethereum) {
      return provider
    }
    if (isAuthorized && selectedChainId === chainId) {
      return defaultProvider
    }
    return provider
  }, [defaultProvider, provider, isAuthorized, selectedChainId, chainId])
}

export default useWeb3Provider
