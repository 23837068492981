import { ThemeOptions } from '@mui/material'
import { getProjectInfo } from './project'

const baseThemeOpts: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },
}

const projectData = getProjectInfo()

export const LightThemeOpts: ThemeOptions = {
  palette: {
    mode: 'light',
    ...projectData.colors.light,
  },
  components: { ...baseThemeOpts.components },
}

export const DarkThemeOpts: ThemeOptions = {
  palette: {
    mode: 'dark',
    ...projectData.colors.dark,
  },
  components: {
    ...baseThemeOpts.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: projectData.colors.backgroundColor,
          background: projectData.colors.background,
        },
      },
    },
  },
}
