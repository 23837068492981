export const DEFAULT_CHAIN_ID = 1
export const DEFAULT_TOKEN_SYMBOL = 'OLT'
export const SYNDICATE_TOKEN_LIST_BASE_URI = 'https://raw.githubusercontent.com/Oneledger/syndicate-token-list/v0.3.0'
export const SYNDICATE_BOWL_BASE_URI = 'https://api-syndicate-bowl.oneledger.network/api/v1'
export const PERCENT_DENOMINATOR = 100
export const MAX_PERCENT = 10000
export const DEFAULT_PROJECT_NAME = 'oneledger'
export const POLLING_INTERVAL = 12000
export const DEFAULT_STAKING_TOKEN = 'MBA'
export const ANALYTICS_URI = 'https://info.moonba.co'
export const DEFAULT_GRAPH_NODE_URI = 'https://mainnet-graph.oneledger.network'
export const MOONBA_TOKEN_LIST_BASE_URI = 'https://raw.githubusercontent.com/moonba-co/moonba-token-list/v0.1.4'
export const MOONBA_MANAGER_GRAPH_URI = (
  process.env.REACT_APP_MOONBA_MANAGER_GRAPH_URI
  || 'http://localhost:9999/graphql'
)
