import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Typography, CircularProgress, useMediaQuery } from '@mui/material'

import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Web3Provider,
} from '@ethersproject/providers'

import { ethers } from 'ethers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ModalType, openModal } from '../../features/modal/modalSlice'
import {
  BridgeDirectionName,
  selectDirection,
  setBalance,
  setValue,
} from '../../features/bridge/bridgeSlice'
import { prettyFormat } from '../../helpers/utilities'
import { DEFAULT_TOKEN_SYMBOL } from '../../helpers/constants'
import {
  AddTokenButton, TokenButton, BridgeChainButton, SafeMaxButton,
} from '../buttons'
import { Currency } from '../../entities'
import useFetchBalancePerCurrencies from '../../hooks/useFetchBalancePerCurrencies'

const InputBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: (theme.palette.mode === 'light') ? '#f5f5f9' : '#393939',
  padding: theme.spacing(2),
  borderRadius: 16,
}))

interface BridgeInputProps {
  labelName: string
  direction: BridgeDirectionName
}

interface BalanceProps {
  account: string
  direction: BridgeDirectionName
  provider: Web3Provider
  currency: Currency
}

const AccountBalance = ({
  account, direction, provider, currency,
}: BalanceProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const {
    data, isLoading, isFetching,
  } = useFetchBalancePerCurrencies({
    provider,
    fetchInfo: {
      address: account,
      currency,
    },
  })

  const balance = Array.isArray(data) ? data[0] : data

  // set direction balance for transfer purposes in global state
  // NOTE: should only used for source direction right now
  useEffect(() => {
    if (direction !== BridgeDirectionName.SOURCE) return
    dispatch(setBalance((balance) || ethers.constants.Zero))
  }, [dispatch, direction, balance])

  return (
    <>
      {(isLoading || isFetching) ? (
        <Typography
          variant="body2"
          color="secondary"
          noWrap
          sx={{ marginRight: 1 }}
        >
          <CircularProgress
            disableShrink
            color="secondary"
            size={10}
          />
        </Typography>
      ) : (
        <Typography
          variant="body2"
          color="secondary"
          noWrap
          sx={{ marginRight: 0.5 }}
        >
          {
            balance
              ? prettyFormat(balance, currency.decimals)
              : 0
          }
        </Typography>
      )}
      <Typography
        variant="body2"
        color="secondary"
        noWrap
        sx={{ fontWeight: 600 }}
      >
        {currency.symbol}
      </Typography>
      {direction === BridgeDirectionName.SOURCE && (
        <SafeMaxButton
          variant="outlined"
          color="error"
          isLoading={isLoading || isFetching}
          sx={{
            minWidth: 24,
            marginLeft: 1,
            fontSize: '.7rem',
          }}
        />
      )}
    </>
  )
}

const BridgeInput = (
  { labelName, direction }
    : BridgeInputProps,
): JSX.Element => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const bridgeDirection = useAppSelector((state) => state.bridge.directions[direction])
  const bridgeValue = bridgeDirection.value
    ? +formatUnits(bridgeDirection.value, bridgeDirection.currency?.decimals)
    : ''
  const isMobile = useMediaQuery('(max-width:600px)', {
    noSsr: true,
  })

  const { account, library: currentProvider, error } = useWeb3React<Web3Provider>()
  const {
    library: provider, active, chainId,
  } = useWeb3React<Web3Provider>(direction)
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  const handleTokenButton = () => {
    dispatch(selectDirection(direction))
    dispatch(openModal(ModalType.SELECT_BRIDGE_TOKEN))
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!bridgeDirection?.currency) return

    let { value } = event.target
    value = +value >= 0 ? value : ''

    if (value.includes('-') || value.length > 32) return

    const splitedV = value.split('.')[1]
    if (splitedV && splitedV.length > bridgeDirection.currency.decimals) return

    if (!bridgeDirection.currency) return

    dispatch(setValue(value ? parseUnits(value, bridgeDirection.currency.decimals) : undefined))
  }

  // console.log(`BridgeInput: direction - ${direction}, info`, bridgeDirection)
  const disabled = !wallet.isAuthorized
    || typeof bridgeDirection?.chainId === 'undefined'
    || isUnsupportedChainIdError

  return (
    <InputBox>
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          sx={{
            justifyContent: 'space-between',
            marginBottom: 2,
            flexDirection: (isMobile) ? 'column' : 'inherit',
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Typography
              variant="body2"
              color="secondary"
              noWrap
              sx={{ marginRight: 0.5 }}
            >
              {labelName}
            </Typography>
            {wallet.isAuthorized && (
              <BridgeChainButton direction={direction} chainId={bridgeDirection?.chainId} />
            )}
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            {active && chainId && account && provider && bridgeDirection.currency ? (
              <AccountBalance
                account={account}
                direction={direction}
                provider={provider}
                currency={bridgeDirection.currency}
              />
            ) : (
              <>
                <Typography
                  variant="body2"
                  color="secondary"
                  noWrap
                  sx={{ marginRight: 0.5 }}
                >
                  0
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  noWrap
                  sx={{ fontWeight: 600 }}
                >
                  {DEFAULT_TOKEN_SYMBOL}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={8} display="flex">
          <TextField
            fullWidth
            placeholder="0.0"
            InputProps={{
              // patern: '[0-9]*(.[0-9]+)?',
              sx: {
                borderRadius: 0,
                fontSize: 24,
              },
              disableUnderline: disabled || direction === BridgeDirectionName.TARGET,
            }}
            variant="standard"
            autoComplete="off"
            autoCorrect="off"
            InputLabelProps={{ shrink: false, color: 'secondary' }}
            color="secondary"
            type="number"
            value={account ? bridgeValue : ''}
            onChange={onInputChange}
            disabled={
              disabled
              || direction === BridgeDirectionName.TARGET
            }
          />
        </Grid>
        <Grid item xs={4} display="flex">
          <TokenButton
            symbol={bridgeDirection?.currency?.symbol}
            logoURI={bridgeDirection?.currency?.logoURI}
            disabled={disabled}
            onClick={handleTokenButton}
            variant="text"
          />
        </Grid>
        <Grid item xs={8} display="flex" />
        <Grid item xs={4} display="flex">
          {
            bridgeDirection?.currency
              && !bridgeDirection.currency.isNative
              && currentProvider
              ? (
                <AddTokenButton
                  provider={currentProvider}
                  currency={bridgeDirection.currency}
                  fullWidth
                />
              ) : (
                <Box sx={{ height: 30 }} />
              )
          }
        </Grid>
      </Grid>
    </InputBox>
  )
}

export default BridgeInput
