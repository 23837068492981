import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { getProjectInfo } from '../../project'

const warningColors = getProjectInfo().colors.warningPaper

const WarningPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: warningColors.color,
  border: `solid 1px ${warningColors.color}`,
  borderRadius: theme.spacing(2),
  boxShadow: '#33333333 0px 4px 21px',
  maxWidth: 500,
  width: 'calc(100vw - 1.8rem)',
  margin: theme.spacing(2),
  backgroundColor: warningColors.backgroundColor,
  padding: theme.spacing(2),
  '& p': {
    fontSize: '.75rem',
  },
}))

export default WarningPaper
