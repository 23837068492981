import { BigNumber } from 'ethers'

import ILaunchpadIDOABI from '../../abi/ILaunchpadIDO.abi.json'
import { useWeb3RequestQuery } from '../../features/api/web3ApiSlice'
import { HookProvider } from '../types'
import { TokenIndexer } from '../../features/dex/dexSlice'

interface FetchPriceData {
  provider?: HookProvider
  contract?: string
  amount?: BigNumber
  direction?: TokenIndexer
}

interface FetchPriceResponse {
  amount?: BigNumber
  isFetching: boolean
  isLoading: boolean
}

const useFetchPrice = ({
  provider, contract, amount, direction,
}: FetchPriceData): FetchPriceResponse => {
  let method = ''

  switch (direction) {
    case TokenIndexer.TOKEN0:
      method = 'calculatePurchaseAmount'
      break
    case TokenIndexer.TOKEN1:
      method = 'calculateSaleAmount'
      break
    // no default
  }

  const skip = (
    !amount
    || !method
    || !direction
    || !contract
    || !provider
  )

  const {
    data, isLoading, isFetching,
  } = useWeb3RequestQuery({
    provider,
    request: {
      address: contract,
      abi: ILaunchpadIDOABI,
      method,
      params: [amount],
    },
  }, {
    skip,
  })

  if (!amount) {
    return {
      amount: undefined,
      isLoading,
      isFetching,
    }
  }

  const responseAmount = data ? data as BigNumber : undefined

  return {
    amount: responseAmount,
    isLoading,
    isFetching,
  }
}

export default useFetchPrice
