import {
  ethers, BigNumber, FixedNumber,
} from 'ethers'

import { HookProvider, useFetchDexCurrencies } from '..'
import { Currency } from '../../entities'
import { getGqlCurrency } from '../../entities/utils'
import { useGraphRequestQuery } from '../../features/api/graphNodeApiSlice'
import {
  DEX_SUBGRAPH_NAME, GET_LIQUIDITY_POSITION, GET_PAIR, LiquidityPosition, Pair,
} from '../../graphs/dex'

import {
  getChainData, unwrapTokenSymbol, getSighash, parseBigNumber, truncate,
} from '../../helpers/utilities'
import { useMulticall2 } from '../multicall'
import {
  megaPoolInterface,
} from './utils'

interface InfoFarmProps {
  provider?: HookProvider
  pairAddress: string
  farmAddress: string
  account: string
  chainId?: number
}

interface FarmData {
  apr: number
  staked: BigNumber
  earned: BigNumber
  balance: BigNumber
  liquidityShare: FixedNumber
  distributionPeriodDays: FixedNumber
  totalRewards: FixedNumber
  rewardRate: BigNumber
  weeklyRewardRate: BigNumber
  userWeeklyRewardRate: BigNumber
}

interface PositionData {
  totalBalance: FixedNumber
  totalBalanceInUSD: FixedNumber
  farmPoolBalance: FixedNumber
  farmPoolBalanceInUSD: FixedNumber
}

interface InfoFarmResult {
  currency0?: Currency
  currency1?: Currency
  positionData?: PositionData
  farmData?: FarmData
  isLoading: boolean
  isFetching: boolean
}

const useGetFarmInfo = ({
  provider,
  pairAddress,
  farmAddress,
  account,
  chainId,
}: InfoFarmProps): InfoFarmResult => {
  const { getCurrency } = useFetchDexCurrencies()

  const chain = getChainData(chainId)

  const govAddress = chain?.governance?.tokenAddress || ethers.constants.AddressZero

  const { data: pairData, isLoading: isGraphPairLoading, isFetching: isGraphPairFetching } = useGraphRequestQuery({
    graphNodeURL: chain?.graphNodeURL,
    subgraphName: DEX_SUBGRAPH_NAME,
    query: GET_PAIR(
      pairAddress.toLowerCase(),
    ),
  })

  const res0 = pairData as { pair: Pair | null } | undefined

  const { data, isLoading: isGraphLoading, isFetching: isGraphFetching } = useGraphRequestQuery({
    graphNodeURL: chain?.graphNodeURL,
    subgraphName: DEX_SUBGRAPH_NAME,
    query: GET_LIQUIDITY_POSITION(
      pairAddress.toLowerCase(),
      farmAddress.toLowerCase(),
    ),
  }, {
    pollingInterval: 10_000,
  })

  // TODO: Refactor
  const res1 = data as { liquidityPosition: LiquidityPosition | null }
  // eslint-disable-next-line no-nested-ternary
  const liquidityPosition = (res1) ? (res1.liquidityPosition ? (res1.liquidityPosition || null) : null) : null

  const { response, isLoading, isFetching } = useMulticall2({
    provider,
    address: chain?.multicall2address || ethers.constants.AddressZero,
    params: [
      {
        target: farmAddress,
        callData: megaPoolInterface.encodeFunctionData('balanceOf(address)', [account]),
      },
      {
        target: farmAddress,
        callData: getSighash('totalSupply()'),
      },
      {
        target: farmAddress,
        callData: megaPoolInterface.encodeFunctionData('earned(address,address)', [govAddress, account]),
      },
      {
        target: farmAddress,
        callData: megaPoolInterface.encodeFunctionData('rewardTokenInfo(address)', [govAddress]),
      },
      {
        target: pairAddress,
        callData: megaPoolInterface.encodeFunctionData('balanceOf(address)', [account]),
      },
    ],
    skip: (
      farmAddress === ethers.constants.AddressZero
      || pairAddress === ethers.constants.AddressZero
      || !provider
      || !chainId
    ),
  })

  const result: InfoFarmResult = {
    isLoading: isGraphPairLoading || isGraphLoading || isLoading,
    isFetching: isGraphPairFetching || isGraphFetching || isFetching,
  }

  if (res0?.pair && chainId) {
    let currency0 = getCurrency(chainId, unwrapTokenSymbol(res0.pair.token0.symbol))
    let currency1 = getCurrency(chainId, unwrapTokenSymbol(res0.pair.token1.symbol))

    if (!currency0) {
      currency0 = getGqlCurrency(chainId, res0.pair.token0)
    }

    if (!currency1) {
      currency1 = getGqlCurrency(chainId, res0.pair.token1)
    }

    result.currency0 = currency0
    result.currency1 = currency1
  }

  if (liquidityPosition) {
    const totalBalance = FixedNumber.from(liquidityPosition.pair.totalSupply)
    const totalBalanceInUSD = FixedNumber.from(truncate(liquidityPosition.pair.reserveUSD, 4))
    const farmPoolBalance = FixedNumber.from(liquidityPosition.liquidityTokenBalance)
    const farmPoolBalanceInUSD = farmPoolBalance.mulUnsafe(totalBalanceInUSD).divUnsafe(totalBalance)

    result.positionData = {
      totalBalance,
      totalBalanceInUSD,
      farmPoolBalance,
      farmPoolBalanceInUSD,
    }
  }

  if (response) {
    const staked = parseBigNumber(response[0])
    const tvl = parseBigNumber(response[1])
    const earned = parseBigNumber(response[2])

    const parsedRewardData = (response[3] !== '0x')
      ? megaPoolInterface.decodeFunctionResult('rewardTokenInfo(address)', response[3])[0]
      : {
        rewardRate: BigNumber.from(0),
        lastUpdateTime: BigNumber.from(0),
        periodFinish: BigNumber.from(0),
      }

    const {
      rewardRate, lastUpdateTime, periodFinish,
    } = parsedRewardData as unknown as {
      rewardRate: BigNumber
      lastUpdateTime: BigNumber
      periodFinish: BigNumber
    }

    const balance = parseBigNumber(response[4])

    const distributionPeriodDays = FixedNumber.from(periodFinish.toString())
      .subUnsafe(FixedNumber.from(lastUpdateTime.toString()))
      .divUnsafe(FixedNumber.from(3600 * 24))

    const totalRewards = FixedNumber.from(rewardRate.toString())
      .mulUnsafe(FixedNumber.from(3600 * 24))
      .mulUnsafe(distributionPeriodDays)

    // TODO: Fix APR calculation
    const apr = (!tvl.eq(ethers.constants.Zero))
      ? +totalRewards
        .mulUnsafe(FixedNumber.from(100))
        .mulUnsafe(FixedNumber.from(365))
        .divUnsafe(
          FixedNumber.from(tvl.toString()).mulUnsafe(distributionPeriodDays),
        )
        .toUnsafeFloat()
        .toFixed(2)
      : Infinity

    const weeklyRewardRate = rewardRate.mul(BigNumber.from(3600 * 24 * 7))

    const liquidityShare = tvl.gt(0)
      ? FixedNumber.from(staked.toString())
        .mulUnsafe(FixedNumber.from(100))
        .divUnsafe(FixedNumber.from(tvl.toString()))
      : FixedNumber.from(0)

    const userWeeklyRewardRate = weeklyRewardRate
      .mul(BigNumber.from(Math.floor(+liquidityShare.toString())))
      .div(BigNumber.from(100))

    result.farmData = {
      apr,
      staked,
      earned,
      balance,
      liquidityShare,
      distributionPeriodDays,
      totalRewards,
      rewardRate,
      weeklyRewardRate,
      userWeeklyRewardRate,
    }
  }

  return result
}

export default useGetFarmInfo
