import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

const FormPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: theme.spacing(2),
  boxShadow: '#33333333 0px 4px 21px',
  maxWidth: 500,
  width: 'calc(100vw - 1.8rem)',
  margin: theme.spacing(2),
}))

export default FormPaper
