import { useState } from 'react'
import { ethers, BigNumber } from 'ethers'

import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch } from '../../app/hooks'
import useFetchMetamaskAPI from '../useFetchMetamaskAPI'
import IMoonbaRouterABI from '../../abi/IMoonbaRouter.abi.json'
import IWETH9ABI from '../../abi/IWETH9.abi.json'
import {
  initializeTx, LocalTxStatus,
} from '../../features/wallet/transactionSlice'
import { getChainData, getDeadline } from '../../helpers/utilities'
import { Currency } from '../../entities'
import { getDexPath, isWrapDirection } from './utils'
import { TokenIndexer } from '../../features/dex/dexSlice'

interface SwapData {
  provider?: Web3Provider
  chainId: number
  to: string
  deadline: number
}

interface SwapRequest {
  currency0: Currency
  currency1: Currency
  amountIn: BigNumber
  amountOut: BigNumber
  direction: TokenIndexer
}

interface SwapAction {
  swap(params: SwapRequest): Promise<string | undefined>
  isLoading: boolean
}

const useSwap = ({
  provider, chainId, to, deadline,
}: SwapData): SwapAction => {
  const dispatch = useAppDispatch()

  const chain = getChainData(chainId)
  const { callContract } = useFetchMetamaskAPI(provider)
  const [isLocked, setLock] = useState(false)

  const contract = chain?.dex?.routerAddress

  const swap = async ({
    currency0, currency1,
    amountIn, amountOut,
    direction,
  }: SwapRequest): Promise<string | undefined> => {
    if (!contract || isLocked) return undefined

    const { weth9 } = getDexPath(currency0, currency1)

    if (!weth9 || !chain) return undefined

    // eslint-disable-next-line no-nested-ternary
    const path = (currency0.isNative)
      ? [weth9.address, currency1.address]
      : (
        (currency1.isNative)
          ? [currency0.address, weth9.address]
          : [currency0.address, currency1.address]
      )

    let [method, params, value]: [string, unknown[], BigNumber | undefined] = ['', [
      path, to, getDeadline(deadline),
    ], undefined]

    const isUnwrap = isWrapDirection(currency0, currency1)
    const isWrap = isWrapDirection(currency1, currency0)

    let abi: ethers.ContractInterface = IMoonbaRouterABI
    let sendTo: string = contract

    if (isUnwrap) {
      method = 'withdraw'
      params = [amountOut]
      abi = IWETH9ABI
      sendTo = weth9.address
    } else if (isWrap) {
      method = 'deposit'
      params = []
      value = amountIn
      abi = IWETH9ABI
      sendTo = weth9.address
    } else {
      // TODO: Add INSUFFICIENT AMOUNT CHECK if low liquidity
      switch (direction) {
        case TokenIndexer.TOKEN0:
          if (currency0.isNative) {
            // TESTED
            method = 'swapExactETHForTokens'
            params = [amountOut, ...params]
            value = amountIn
          } else if (currency1.isNative) {
            // TESTED
            method = 'swapExactTokensForETH'
            params = [amountIn, amountOut, ...params]
          } else {
            method = 'swapExactTokensForTokens'
            params = [amountIn, amountOut, ...params]
          }
          break
        case TokenIndexer.TOKEN1:
          if (currency0.isNative) {
            // TESTED
            method = 'swapETHForExactTokens'
            params = [amountOut, ...params]
            value = amountIn
          } else if (currency1.isNative) {
            method = 'swapTokensForExactETH'
            params = [amountOut, amountIn, ...params]
            // TESTED
          } else {
            method = 'swapTokensForExactTokens'
            params = [amountOut, amountIn, ...params]
          }
          break
        default:
          return undefined
      }
    }

    setLock(true)

    const txHash = await callContract({
      from: to,
      value,
      contract: {
        address: sendTo,
        abi,
        method,
        params,
      },
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: sendTo,
        value: value || ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi,
        method,
        params,
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    swap,
    isLoading: isLocked,
  }
}

export default useSwap
