import { useState } from 'react'
import { ethers, BigNumber } from 'ethers'

import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch } from '../../app/hooks'
import useFetchMetamaskAPI from '../useFetchMetamaskAPI'
import IMoonbaRouterABI from '../../abi/IMoonbaRouter.abi.json'
import {
  initializeTx, LocalTxStatus,
} from '../../features/wallet/transactionSlice'
import { getChainData, getDeadline } from '../../helpers/utilities'
import { Currency } from '../../entities'
import { calculateAmountWithSlippage, getDexPath } from './utils'

interface LiquidityData {
  provider?: Web3Provider
  chainId: number
  to: string
  deadline: number
}

interface AddLiqudityParams {
  currency0: Currency
  currency1: Currency
  amount0Desired: BigNumber
  amount1Desired: BigNumber
  slippage: number
}

interface LiquidityAction {
  addLiquidity(params: AddLiqudityParams): Promise<string | undefined>
  isLoading: boolean
}

const useAddLiquidity = ({
  provider, chainId, to, deadline,
}: LiquidityData): LiquidityAction => {
  const dispatch = useAppDispatch()
  const [isLocked, setLock] = useState(false)

  const chain = getChainData(chainId)
  const { callContract } = useFetchMetamaskAPI(provider)

  const contract = chain?.dex?.routerAddress

  const addLiquidity = async ({
    currency0, currency1,
    amount0Desired, amount1Desired,
    slippage,
  }: AddLiqudityParams): Promise<string | undefined> => {
    if (!contract || isLocked) return undefined

    const { weth9 } = getDexPath(currency0, currency1)

    if (!weth9 || !chain) return undefined

    let [method, params, value]: [string, unknown[], BigNumber | undefined] = ['', [
      to, getDeadline(deadline),
    ], undefined]

    const amount0Min = calculateAmountWithSlippage(amount0Desired, slippage)
    const amount1Min = calculateAmountWithSlippage(amount1Desired, slippage)

    if (currency0.isNative) {
      value = amount0Desired
      method = 'addLiquidityETH'
      params = [currency1.address, amount1Desired, amount1Min, amount0Min, ...params]
    } else if (currency1.isNative) {
      value = amount1Desired
      method = 'addLiquidityETH'
      params = [currency0.address, amount0Desired, amount0Min, amount1Min, ...params]
    } else {
      method = 'addLiquidity'
      params = [currency0.address, currency1.address, amount0Desired, amount1Desired, amount0Min, amount1Min, ...params]
    }

    const txHash = await callContract({
      from: to,
      value,
      contract: {
        address: contract,
        abi: IMoonbaRouterABI,
        method,
        params,
      },
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: contract,
        value: value || ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi: IMoonbaRouterABI,
        method,
        params,
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    addLiquidity,
    isLoading: isLocked,
  }
}

export default useAddLiquidity
