import React, { useMemo } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CircularProgress from '@mui/material/CircularProgress'

import { useAppSelector } from '../../../app/hooks'

import { WarningPaper, BasePaper } from '../../papers'
import { PageBox } from '../../boxes'
import {
  getGovernanceInfo, getPrioritySymbol,
} from '../../../helpers/utilities'
import { DEFAULT_STAKING_TOKEN } from '../../../helpers/constants'
import ImgIcon from '../../icons/ImgIcon'
import { useGetFarmInfo } from '../../../hooks/governance'
import useWeb3Provider from '../../../hooks/useProjectProvider'

interface FarmPoolPaperProps {
  chainId: number
  pairAddress: string
  farmAddress: string
}

const FarmPoolPaper = ({
  chainId, pairAddress, farmAddress,
}: FarmPoolPaperProps): JSX.Element | null => {
  const { account } = useAppSelector((state) => state.wallet)

  const provider = useWeb3Provider(chainId)

  const {
    currency0, currency1, farmData, positionData, isLoading,
  } = useGetFarmInfo({
    provider,
    pairAddress,
    farmAddress,
    account,
    chainId,
  })

  if (isLoading) {
    return (
      <BasePaper elevation={4}>
        <CircularProgress color="secondary" size={36} />
      </BasePaper>
    )
  }

  return (
    <BasePaper elevation={4}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: 0.5,
            marginBottom: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box mr={1}>
              <ImgIcon src={currency0?.logoURI} />
            </Box>
            <Box mr={1}>
              <ImgIcon src={currency1?.logoURI} />
            </Box>
            <Typography variant="subtitle2">
              {`${currency0?.symbol} - ${currency1?.symbol} LP`}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            href={`#/farm/${getPrioritySymbol(currency0)}/${getPrioritySymbol(currency1)}/${farmAddress}`}
            size="medium"
          >
            Deposit
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: 0.5,
          }}
        >
          <Typography variant="subtitle2">Total deposited</Typography>
          <Typography variant="subtitle2">
            {`$ ${positionData?.farmPoolBalanceInUSD?.toUnsafeFloat().toFixed(2).toLocaleString() || 0}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: 0.5,
          }}
        >
          <Typography variant="subtitle2">APR</Typography>
          <Typography variant="subtitle2">{`${farmData?.apr}%`}</Typography>
        </Grid>
      </Grid>
    </BasePaper>
  )
}

const farmPools = [
  {
    chainId: 4216137055,
    pairAddress: '0x448a21d2cf7b914c0b596dba23613e1f4b674b38',
    farmAddress: '0x964ec0e6f2f82e40e0084f8b89002ebd8e000a9c',
  },
  {
    chainId: 4216137055,
    pairAddress: '0xb2f9be44084d231d00ba9d3d82b65317ec47100c',
    farmAddress: '0x6127ddce8247d030c057267e64bf8db9f6ac2a44',
  },
]

const FindFarm = (): JSX.Element => {
  const { chainId } = useAppSelector((state) => state.wallet)

  const { currency: govCurrency } = useMemo(() => getGovernanceInfo(chainId), [chainId])
  const govSymbol = govCurrency?.symbol || DEFAULT_STAKING_TOKEN

  return (
    <PageBox>
      <WarningPaper>
        <Grid container>
          <Grid item xs={1}>
            <InfoOutlinedIcon sx={{ width: 0.5, height: 0.5 }} />
          </Grid>
          <Grid item xs={11}>
            <Typography
              align="left"
              variant="subtitle2"
            >
              Moonba liquidity mining
            </Typography>
            <Typography
              align="left"
            >
              {`Deposit your liquidity provider tokens to receive ${govSymbol}, `
                + 'the Moonba protocol governance token.'}
            </Typography>
            {/* <Link href="/#" style={{ float: 'left' }} color="inherit">
              {`Read more about ${govSymbol}`}
            </Link> */}
          </Grid>
        </Grid>
      </WarningPaper>
      <Box
        m={2}
      >
        <Typography
          variant="h6"
          color="secondary"
        >
          Available pools
        </Typography>
      </Box>
      {farmPools.map((pool) => (
        <FarmPoolPaper
          key={`${pool.pairAddress}-${pool.farmAddress}`}
          {...pool}
        />
      ))}
      {!farmPools.length && (
        <Typography variant="body2">No pools available for the current chain</Typography>
      )}
    </PageBox>
  )
}

export default FindFarm
