import { useMemo, useEffect } from 'react'
import { SxProps, Theme } from '@mui/material/styles'

import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ColorizedButton } from './ColorizedButton'

import {
  getReversedDexDirection, getSelectedDexToken, resetDexAmounts, setDexSelectedAmount, TokenIndexer, TokenState,
} from '../../features/dex/dexSlice'

import { ApproveButton, SmartConnectorWrapper } from '.'
import { IDOPool, useFetchPrice, useParticipateIDO } from '../../hooks/launchpad'

interface ButtonProps {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
  fullWidth?: boolean
  sx?: SxProps<Theme>
}

const BuyButton = (props: ButtonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const token0 = useAppSelector(getSelectedDexToken(TokenIndexer.TOKEN0)) as TokenState
  const token1 = useAppSelector(getSelectedDexToken(TokenIndexer.TOKEN1)) as TokenState
  const { library: provider } = useWeb3React<Web3Provider>()

  const { chainId, account } = useAppSelector((state) => state.wallet)

  // for reverse direction
  const { updateIndexer, extraData } = useAppSelector((state) => state.dex)
  const updateToken = useAppSelector((state) => {
    if (typeof updateIndexer === 'undefined') return undefined
    return state.dex[updateIndexer]
  })
  const reverseIndexer = getReversedDexDirection(updateIndexer)
  const pool = extraData as (IDOPool | undefined)

  const { buyTokens, isLoading } = useParticipateIDO({
    provider,
    chainId,
    to: account,
  })

  const { amount: reversedAmount, isLoading: isPriceLoading, isFetching: isPriceFetching } = useFetchPrice({
    provider,
    contract: token1.currency?.address,
    amount: updateToken?.selectedAmount,
    direction: updateIndexer,
  })

  useEffect(() => {
    if (
      typeof reverseIndexer === 'undefined'
      || typeof updateIndexer === 'undefined'
    ) return

    if (!isPriceLoading && !isPriceFetching) {
      dispatch(setDexSelectedAmount({
        indexer: reverseIndexer,
        amount: reversedAmount,
      }))
    }
  }, [
    dispatch,
    reversedAmount,
    updateIndexer,
    reverseIndexer,
    isPriceLoading,
    isPriceFetching,
  ])

  const handleClick = async () => {
    if (
      !token0.currency
      || !token0.approved
      || !token0.selectedAmount
      || !token1.currency
    ) return

    const txHash = await buyTokens({
      currency: token0.currency,
      amount: token0.selectedAmount,
      idoAddress: token1.currency.address,
    })
    if (txHash) {
      dispatch(resetDexAmounts())
    }
  }

  const { disabled, text, isFinall } = useMemo(() => {
    if (!pool) {
      return {
        disabled: true,
        text: 'Pool not set',
      }
    }
    if (!token1.currency) {
      return {
        disabled: true,
        text: 'Currency not set',
      }
    }
    if (!token0.selectedAmount || !token1.selectedAmount) {
      return {
        disabled: true,
        text: 'Enter an amount',
      }
    }
    if (!token0.maxAmount || token0.selectedAmount?.gt(token0.maxAmount)) {
      return {
        disabled: true,
        text: `Insufficient ${token0.currency?.symbol} balance`,
      }
    }
    if (
      !token1.maxAmount
      || token1.selectedAmount?.gt(token1.maxAmount)
    ) {
      return {
        disabled: true,
        text: 'Max allocation balance exceed',
      }
    }
    if (
      token0.selectedAmount?.eq(ethers.constants.Zero)
    ) {
      return {
        disabled: true,
        text: 'Max allocation balance exceed',
      }
    }
    return {
      disabled: !token0.approved,
      text: 'Buy',
      isFinall: true,
    }
  }, [pool, token0, token1])

  return (
    <SmartConnectorWrapper variant="outlined" color="secondary" size="large">
      <>
        {isFinall && (
          <ApproveButton
            indexer={TokenIndexer.TOKEN0}
            contract={token1.currency?.address}
            {...props}
          />
        )}
        <ColorizedButton
          fullWidth
          disabled={disabled}
          onClick={handleClick}
          loading={isLoading}
          {...props}
        >
          {text}
        </ColorizedButton>
      </>
    </SmartConnectorWrapper>
  )
}

export default BuyButton
