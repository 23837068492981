import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'

import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'
import {
  getNowTs, getTimeRemainingStr, prettyFormat,
} from '../../helpers/utilities'
import { Currency } from '../../entities'

interface IDOProgressBarProps {
  startTime: number
  endTime: number
  current: BigNumber
  total: BigNumber
  rate: BigNumber
  decimals: number
  buyToken: Currency
  sellToken: Currency
}

const IDOProgressBar: React.FC<LinearProgressProps & IDOProgressBarProps> = ({
  startTime, endTime, current, total, rate, decimals, buyToken, sellToken, ...props
}) => {
  const [realTimeStr, setRealTimeStr] = useState('')

  const percentage = Math.round(current
    .mul(BigNumber.from('10000'))
    .div(total)
    .toNumber() / 100)

  const currentInSell = current
    .mul(parseUnits('1', 6))
    .div(rate)

  props.value = +percentage.toFixed(2)

  useEffect(() => {
    const changeTime = () => {
      const now = getNowTs()
      // TODO: Add registration and fcfs later
      if (startTime <= now && endTime >= now) {
        setRealTimeStr(`IDO finishes in ${getTimeRemainingStr(endTime)}`)
      } else if (endTime < now) {
        setRealTimeStr(`IDO finished ${getTimeRemainingStr(endTime)} ago`)
      } else if (startTime > now) {
        setRealTimeStr(`IDO starts in ${getTimeRemainingStr(startTime)}`)
      }
    }

    const id = setInterval(changeTime, 1000)
    changeTime()
    return () => {
      clearInterval(id)
    }
  }, [startTime, endTime])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
      pt={2}
      pb={2}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        pb={0.5}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          {realTimeStr && (
            <Typography variant="body2">
              {realTimeStr}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <Typography variant="body2" color="secondary">
            {`${(+percentage.toFixed(2)).toLocaleString()}%`}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <LinearProgress
          variant="determinate"
          sx={{
            height: 6,
            borderRadius: 8,
          }}
          {...props}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        pt={0.5}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="body2" sx={{ opacity: '.8' }}>
            {`${prettyFormat(currentInSell, decimals, 6).toLocaleString()} ${sellToken?.symbol || '-'}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="body2" sx={{ opacity: '.8' }}>
            {`${prettyFormat(current, decimals, 6).toLocaleString()} `
              + `/ ${prettyFormat(total, decimals, 6).toLocaleString()} `
              + `${buyToken.symbol}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default IDOProgressBar
