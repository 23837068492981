import { grey } from '@mui/material/colors'

import { DEFAULT_PROJECT_NAME } from './helpers/constants'

import OneledgerSvg from './assets/icons/oneledger.svg'
import MoonbaSvg from './assets/icons/moonba.svg'
import { RedirectHome, MoonbaHome } from './components/pages'
import { AppRoute } from './helpers/types'

interface ColorModeData {
  primary: {
    light?: string,
    main: string,
    dark?: string,
    contrastText?: string,
  },
  secondary: {
    light?: string,
    main: string,
    dark?: string,
    contrastText?: string,
  },
  tooltip: {
    color: string,
    backgroundColor: string,
    borderColor: string,
  },
}

interface ProjectData {
  pages: AppRoute[]
  infoURL?: string,
  docsURL?: string,
  companyName: string,
  termsURL: string,
  logo: string,
  url: string,
  colors: {
    light: ColorModeData,
    dark: ColorModeData,
    backgroundColor?: string,
    background?: string,
    warningPaper: {
      color: string,
      backgroundColor: string,
    },
  }
}

interface ProjectsData {
  [name: string]: ProjectData
}

const projectData: ProjectsData = {
  oneledger: {
    pages: [
      {
        name: 'Home',
        disabled: true,
        path: '/',
        component: RedirectHome,
      },
    ],
    docsURL: 'https://docs.oneledger.io',
    companyName: 'OneLedger Technology Inc',
    termsURL: 'https://www.oneledger.io/syndicate-bridge-terms-of-service/',
    logo: OneledgerSvg,
    url: 'https://oneledger.io',
    colors: {
      light: {
        primary: {
          main: '#3b8c9e',
        },
        secondary: {
          main: grey[900],
        },
        tooltip: {
          color: '#000000de',
          backgroundColor: '#f5f5f9',
          borderColor: '#2b2b2b',
        },
      },
      dark: {
        primary: {
          main: grey[900],
        },
        secondary: {
          main: '#5ef5cf',
        },
        tooltip: {
          color: 'white',
          backgroundColor: '#1b1b1b',
          borderColor: '#69f6d1',
        },
      },
      backgroundColor: grey[900],
      background: `linear-gradient(0deg, #5ef5cf 0%, #272727 34%, ${grey[900]} 67%)`,
      warningPaper: {
        color: '#2e7a8a',
        backgroundColor: '#5ef5cf',
      },
    },
  },
  moonba: {
    pages: [
      {
        name: 'Home',
        disabled: false,
        path: '/',
        component: MoonbaHome,
      },
    ],
    infoURL: 'https://info.moonba.co',
    companyName: 'Moonba',
    termsURL: 'https://share.hsforms.com/1ZEfH8_xhQZGEl1uOhcJTCw3dkay',
    logo: MoonbaSvg,
    url: '#/',
    colors: {
      light: {
        primary: {
          main: '#fabf2f',
        },
        secondary: {
          main: grey[900],
        },
        tooltip: {
          color: '#000000de',
          backgroundColor: '#f5f5f9',
          borderColor: grey[900],
        },
      },
      dark: {
        primary: {
          main: grey[900],
        },
        secondary: {
          main: '#fabf2f',
        },
        tooltip: {
          color: '#fabf2f',
          backgroundColor: '#1b1b1b',
          borderColor: '#fabf2f',
        },
      },
      backgroundColor: '#fabf2f',
      background: `linear-gradient(0deg, #fabf2f 0%, #272727 34%, ${grey[900]} 67%)`,
      warningPaper: {
        color: grey[800],
        backgroundColor: '#fabf2f',
      },
    },
  },
}

export const getProjectInfo = (): ProjectData => (
  projectData[process.env.REACT_APP_PROJECT_NAME || DEFAULT_PROJECT_NAME]
)
