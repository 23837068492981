import { ethers } from 'ethers'

import { Currency, WETH9 } from '../../entities'

import IMoonbaFactoryABI from '../../abi/IMoonbaFactory.abi.json'
import { getChainData } from '../../helpers/utilities'
import { useWeb3RequestQuery } from '../../features/api/web3ApiSlice'
import { HookProvider } from '../types'

interface GetPairData {
  provider?: HookProvider
  currency0?: Currency
  currency1?: Currency
}

interface Pair {
  address?: string
  isFetching: boolean
  isLoading: boolean
}

const useGetPair = ({
  provider, currency0, currency1,
}: GetPairData): Pair => {
  const chain = getChainData(
    (currency0?.address === ethers.constants.AddressZero)
      ? currency1?.chainId
      : currency0?.chainId,
  )

  const factoryAddress = chain?.dex?.factoryAddress
  const weth9 = WETH9[chain?.chainId || 0]
  const skip = (
    !currency0?.address
    || !currency1?.address
    || currency0.address === currency1.address
    || !weth9
    || !factoryAddress
    || !provider
  )

  let params = []
  if (currency0?.address === ethers.constants.AddressZero) {
    params = [weth9?.address, currency1?.address]
  } else if (currency1?.address === ethers.constants.AddressZero) {
    params = [currency0?.address, weth9?.address]
  } else {
    params = [currency0?.address, currency1?.address]
  }

  const { data: pairData, ...pairQueryData } = useWeb3RequestQuery({
    provider,
    request: {
      address: factoryAddress,
      abi: IMoonbaFactoryABI,
      method: 'getPair',
      params,
    },
  }, {
    skip,
    pollingInterval: 15_000,
  })

  return {
    address: pairData as (string | undefined),
    ...pairQueryData,
  }
}

export default useGetPair
