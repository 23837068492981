import Button from '@mui/material/Button'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getThemeSettings, updateThemeSettings } from '../../features/settings/settingsSlice'

export const ThemeButton = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const themeSettings = useAppSelector(getThemeSettings)

  return (
    <Button
      disableFocusRipple
      onClick={() => dispatch(updateThemeSettings({
        ...themeSettings,
        isDark: !themeSettings.isDark,
      }))}
    >
      {themeSettings.isDark ? (
        <LightModeIcon />
      ) : (
        <DarkModeIcon />
      )}
    </Button>
  )
}
