import { ReactNode } from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { WarningPaper, FormPaper } from '../../papers'
import { PageBox } from '../../boxes'

interface PageWrapperProps {
  children?: ReactNode
}

const PageWrapper = ({ children }: PageWrapperProps): JSX.Element => (
  <PageBox>
    <WarningPaper>
      <Grid container>
        <Grid item xs={1}>
          <InfoOutlinedIcon sx={{ width: 0.5, height: 0.5 }} />
        </Grid>
        <Grid item xs={11}>
          <Typography
            align="left"
            variant="subtitle2"
          >
            Liquidity provider rewards
          </Typography>
          <Typography
            align="left"
          >
            Liquidity providers earn a 0.25% fee on all trades proportional to their share of the pool.
            Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </Typography>
          <Link href="/#" style={{ float: 'left' }} color="inherit">
            Read more about providing liquidity
          </Link>
        </Grid>
      </Grid>
    </WarningPaper>
    <FormPaper>
      {children}
    </FormPaper>
  </PageBox>
)

export default PageWrapper
