import { Bridge } from './components/pages'
import {
  Swap, Pool, AddLiquidity, FindPool, RemoveLiquidity,
} from './components/pages/dex'
import { FindFarm, Farm, Stake } from './components/pages/governance'
import { FindIDO, IDO } from './components/pages/launchpad'
import { AppRoute } from './helpers/types'
import { getProjectInfo } from './project'

const projectInfo = getProjectInfo()

const baseRoutes: AppRoute[] = projectInfo.pages.slice(0)

if (process.env.REACT_APP_ENABLE_IDO === 'true') {
  baseRoutes.push(
    {
      name: 'IDO',
      disabled: false,
      path: '/ido',
      component: FindIDO,
    },
    {
      name: 'IDO detailed',
      disabled: true,
      path: '/ido/:token0',
      component: IDO,
    },
  )
}

if (process.env.REACT_APP_ENABLE_DEX === 'true') {
  baseRoutes.push(
    {
      name: 'Swap',
      disabled: false,
      path: '/swap',
      component: Swap,
      params: ['OLT'],
      nested: [
        {
          name: 'Single token',
          path: ':token0',
          component: Swap,
        },
        {
          name: 'Double token',
          path: ':token0/:token1',
          component: Swap,
        },
      ],
    },
    {
      name: 'Pool',
      disabled: false,
      path: '/pool',
      component: Pool,
    },
    {
      name: 'Remove liquidity',
      disabled: true,
      path: '/remove',
      component: RemoveLiquidity,
      nested: [
        {
          name: 'Single token',
          path: ':token0',
          component: RemoveLiquidity,
        },
        {
          name: 'Double token',
          path: ':token0/:token1',
          component: RemoveLiquidity,
        },
      ],
    },
    {
      name: 'Find pool',
      disabled: true,
      path: '/find',
      component: FindPool,
      nested: [
        {
          name: 'Single token',
          path: ':token0',
          component: FindPool,
        },
        {
          name: 'Double token',
          path: ':token0/:token1',
          component: FindPool,
        },
      ],
    },
    {
      name: 'Add liquidity',
      disabled: true,
      path: '/add',
      component: AddLiquidity,
      nested: [
        {
          name: 'Single token',
          path: ':token0',
          component: AddLiquidity,
        },
        {
          name: 'Double token',
          path: ':token0/:token1',
          component: AddLiquidity,
        },
      ],
    },
  )
}

if (
  process.env.REACT_APP_ENABLE_STAKING === 'true'
) {
  baseRoutes.push(
    {
      name: 'Farm',
      disabled: false,
      path: '/farm',
      component: FindFarm,
    },
    {
      name: 'Farm detailed',
      disabled: true,
      path: '/farm/:token0/:token1/:farm',
      component: Farm,
    },
    {
      name: 'Stake',
      disabled: false,
      path: '/stake',
      component: Stake,
    },
  )
}

if (projectInfo.infoURL) {
  baseRoutes.push(
    {
      name: 'Analytics',
      disabled: false,
      path: projectInfo.infoURL,
      component: null,
    },
  )
}

if (
  typeof process.env.REACT_APP_ENABLE_BRIDGE === 'undefined'
  || process.env.REACT_APP_ENABLE_BRIDGE === 'true'
) {
  baseRoutes.push(
    {
      name: 'Bridge',
      disabled: false,
      path: '/bridge',
      component: Bridge,
    },
  )
}

// rest
if (projectInfo.docsURL) {
  baseRoutes.push(
    {
      name: 'Docs',
      disabled: false,
      path: projectInfo.docsURL,
      component: null,
    },
  )
}

export default baseRoutes
