import { Web3Provider } from '@ethersproject/providers'
import LoadingButton from '@mui/lab/LoadingButton'
import { useWeb3React } from '@web3-react/core'

import { useAppSelector } from '../../app/hooks'
import { StakingDirection } from '../../hooks'
import { useStake } from '../../hooks/governance'

interface ButtonProps {
  contract?: string
  action: StakingDirection
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const ClaimButton = ({ contract, action, ...props }: ButtonProps): JSX.Element => {
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const { library: provider } = useWeb3React<Web3Provider>()
  const { execute, isLoading } = useStake({
    provider,
    chainId,
    to: account,
    contract,
  })
  return (
    <LoadingButton
      color="error"
      size="small"
      loading={isLoading}
      onClick={() => execute({ action })}
      sx={{ height: 22, fontSize: '0.7rem' }}
      style={{ textTransform: 'none' }}
      {...props}
    >
      Claim
    </LoadingButton>
  )
}

export default ClaimButton
