import { Token } from './token'

/**
 * Known WETH9 implementation addresses, used in our implementation of Ether#wrapped
 */
export const WETH9: { [chainId: number]: Token } = {
  // ethereum and testnets
  1: new Token(1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'WETH9', 'Wrapped Ether'),
  3: new Token(3, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'WETH9', 'Wrapped Ether'),
  // oneledger and testnets
  311752642: new Token(311752642, '0x01586239B56ca158f1e31e4c6A07B3Ae59D623B5', 18, 'WOLT', 'Wrapped OLT'),
  4216137055: new Token(4216137055, '0x48e821241B953F110e295fD660Bd9bF988212B4e', 18, 'WOLT', 'Wrapped OLT'),
  // binance and testnets
  56: new Token(56, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
  97: new Token(97, '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', 18, 'WBNB', 'Wrapped BNB'),
}
