import React, {
  useState, useCallback, useEffect,
} from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import TextField from '@mui/material/TextField'
import { styled, SxProps, Theme } from '@mui/material/styles'

import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import {
  Web3Provider,
} from '@ethersproject/providers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

import { useAppDispatch, useAppSelector } from '../../app/hooks'

import {
  AddTokenButton,
  DexMaxButton,
  SmartConnectorWrapper,
  StakingButton,
} from '../buttons'
import { FormPaper } from '../papers'
import { AccountBalance } from '../web3'
import {
  getSelectedDexToken, setDexCurrency, setDexMaxAmount, setDexSelectedAmount, TokenIndexer,
} from '../../features/dex/dexSlice'
import { StakingDirection } from '../../hooks'
import { Currency } from '../../entities'

const InputBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: (theme.palette.mode === 'light') ? '#f5f5f9' : '#393939',
  padding: theme.spacing(2),
  borderRadius: 16,
}))

interface StakeFormBoxProps {
  sx?: SxProps<Theme>
  label?: string

  stakeCurrency?: Currency
  farmCurrency?: Currency
}

const StakeFormBox = ({
  sx,
  label,

  stakeCurrency,
  farmCurrency,
}: StakeFormBoxProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { currency, selectedAmount } = useAppSelector(getSelectedDexToken(TokenIndexer.STAKING))
  const { account, chainId } = useAppSelector((state) => state.wallet)

  const {
    active, library: provider,
  } = useWeb3React<Web3Provider>()

  const [action, setAction] = useState<StakingDirection>('stake')
  const [maxWalletAmount, setMaxWalletAmount] = useState<BigNumber | undefined>()
  const [maxStakedAmount, setMaxStakedAmount] = useState<BigNumber | undefined>()

  const isStake = action === 'stake'

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAction: StakingDirection,
  ) => {
    if (newAction !== null) {
      setAction(newAction)
    }
  }

  const parsedAmount = selectedAmount
    ? +formatUnits(selectedAmount, currency?.decimals)
    : ''

  const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target
    value = +value >= 0 ? value : ''

    if (value.includes('-') || value.length > 32) return

    const splitedV = value.split('.')[1]
    if (splitedV && splitedV.length > 18) return

    if (!stakeCurrency) return

    dispatch(setDexSelectedAmount({
      indexer: TokenIndexer.STAKING,
      amount: value ? parseUnits(value, stakeCurrency.decimals) : undefined,
    }))
  }, [dispatch, stakeCurrency])

  useEffect(() => {
    dispatch(setDexCurrency({
      indexer: TokenIndexer.STAKING,
      currency: stakeCurrency,
    }))
  }, [dispatch, stakeCurrency])

  useEffect(() => {
    if (!maxWalletAmount || !maxStakedAmount) return

    // if (
    //   selectedAmount
    //   && (
    //     (isStake && maxWalletAmount.eq(selectedAmount))
    //     || (!isStake && maxStakedAmount.eq(selectedAmount))
    //   )
    // ) return

    dispatch(setDexMaxAmount({
      indexer: TokenIndexer.STAKING,
      amount: (isStake) ? maxWalletAmount : maxStakedAmount,
    }))
  }, [dispatch, isStake, selectedAmount, maxWalletAmount, maxStakedAmount])

  return (
    <FormPaper sx={sx}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>
          {(stakeCurrency)
            ? `Your ${label || stakeCurrency?.symbol || '-'} Stake`
            : 'Staking not available on this network'}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 4,
        }}
      >
        <ToggleButtonGroup
          color="secondary"
          value={action}
          exclusive
          onChange={handleChange}
          fullWidth
          size="small"
        >
          <ToggleButton value="stake">Stake</ToggleButton>
          <ToggleButton value="unstake">Unstake</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <InputBox>
        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              justifyContent: 'space-between',
              marginBottom: 2,
              flexDirection: 'column',
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              {active && chainId && account && provider && farmCurrency && (
                <AccountBalance
                  label="Current stake:"
                  account={account}
                  indexer={TokenIndexer.STAKING}
                  currency={farmCurrency}
                  onBalanceUpdate={(balance) => setMaxStakedAmount(balance)}
                />
              )}
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              {active && chainId && account && provider && stakeCurrency && (
                <AccountBalance
                  label="Wallet Balance:"
                  account={account}
                  indexer={TokenIndexer.STAKING}
                  currency={stakeCurrency}
                  onBalanceUpdate={(balance) => setMaxWalletAmount(balance)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={10} display="flex">
            <TextField
              fullWidth
              placeholder="0.0"
              InputProps={{
                // patern: '[0-9]*(.[0-9]+)?',
                sx: {
                  borderRadius: 0,
                  fontSize: 24,
                },
                disableUnderline: (!stakeCurrency),
              }}
              onChange={onInputChange}
              variant="standard"
              autoComplete="off"
              autoCorrect="off"
              InputLabelProps={{ shrink: false, color: 'secondary' }}
              color="secondary"
              type="number"
              value={parsedAmount}
              disabled={!stakeCurrency}
            />
          </Grid>
          <Grid item xs={2} display="flex">
            <DexMaxButton
              indexer={TokenIndexer.STAKING}
              isLoading={!maxWalletAmount || !maxStakedAmount}
              variant="outlined"
              color="error"
              fullWidth
              sx={{
                fontSize: '.7rem',
              }}
            />
          </Grid>
          {provider && stakeCurrency && (
            <Grid
              item
              xs={12}
              mt={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AddTokenButton
                provider={provider}
                currency={stakeCurrency}
                text={`Add ${label || stakeCurrency.symbol} to wallet`}
              />
            </Grid>
          )}
        </Grid>
      </InputBox>
      <Box sx={{ marginTop: 4 }}>
        <SmartConnectorWrapper
          checkDex
          variant="outlined"
          color="secondary"
          size="large"
        >
          <StakingButton action={action} label={label} stakingAddress={farmCurrency?.address} />
        </SmartConnectorWrapper>
      </Box>
    </FormPaper>
  )
}

export default StakeFormBox
