import { useState } from 'react'
import { ethers, BigNumber } from 'ethers'

import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch } from '../../app/hooks'
import useFetchMetamaskAPI from '../useFetchMetamaskAPI'
import IMoonbaRouterABI from '../../abi/IMoonbaRouter.abi.json'
import {
  initializeTx, LocalTxStatus,
} from '../../features/wallet/transactionSlice'
import { getChainData, getDeadline } from '../../helpers/utilities'
import { Currency } from '../../entities'
import { getDexPath } from './utils'

interface RemoveLiquidityData {
  provider?: Web3Provider
  chainId: number
  to: string
  deadline: number
}

interface RemoveLiquidityRequest {
  currency0: Currency
  currency1: Currency
  liquidity: BigNumber
  amountMin0: BigNumber
  amountMin1: BigNumber
}

interface RemoveLiquidityAction {
  removeLiquidity(params: RemoveLiquidityRequest): Promise<string | undefined>
  isLoading: boolean
}

const useRemoveLiquidity = ({
  provider, chainId, to, deadline,
}: RemoveLiquidityData): RemoveLiquidityAction => {
  const dispatch = useAppDispatch()

  const chain = getChainData(chainId)
  const { callContract } = useFetchMetamaskAPI(provider)
  const [isLocked, setLock] = useState(false)

  const contract = chain?.dex?.routerAddress

  const removeLiquidity = async ({
    currency0, currency1, liquidity,
    amountMin0, amountMin1,
  }: RemoveLiquidityRequest): Promise<string | undefined> => {
    if (!contract || isLocked) return undefined

    const { weth9 } = getDexPath(currency0, currency1)

    if (!weth9 || !chain) return undefined

    let [method, params]: [string, unknown[]] = ['', [
      to, getDeadline(deadline),
    ]]

    if (currency0.isNative) {
      method = 'removeLiquidityETH'
      params = [currency1.address, liquidity, amountMin1, amountMin0, ...params]
    } else if (currency1.isNative) {
      method = 'removeLiquidityETH'
      params = [currency0.address, liquidity, amountMin0, amountMin1, ...params]
    } else {
      method = 'removeLiquidity'
      params = [currency0.address, currency1.address, liquidity, amountMin0, amountMin1, ...params]
    }
    setLock(true)

    const txHash = await callContract({
      from: to,
      contract: {
        address: contract,
        abi: IMoonbaRouterABI,
        method,
        params,
      },
    })
    if (txHash) {
      dispatch(initializeTx({
        txHash,
        chainId,
        from: to,
        to: contract,
        value: ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi: IMoonbaRouterABI,
        method,
        params,
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
    return txHash
  }

  return {
    removeLiquidity,
    isLoading: isLocked,
  }
}

export default useRemoveLiquidity
