import { useMemo } from 'react'

import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ColorizedButton } from './ColorizedButton'

import { useStake } from '../../hooks/governance'
import { ApproveButton, SmartConnectorWrapper } from '.'
import { StakingDirection, useFetchBalancePerCurrencies } from '../../hooks'
import { getSelectedDexToken, resetDexAmount, TokenIndexer } from '../../features/dex/dexSlice'

interface ButtonProps {
  action: StakingDirection
  stakingAddress?: string
  label?: string
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const actionMap = {
  stake: {
    label: 'Stake',
  },
  unstake: {
    label: 'Unstake',
  },
} as { [name: string]: { label: string } }

const StakingButton = ({
  action, stakingAddress, label, ...props
}: ButtonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { library: provider } = useWeb3React<Web3Provider>()
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const token = useAppSelector(getSelectedDexToken(TokenIndexer.STAKING))

  const isStake = action === 'stake'

  const { execute, isLoading } = useStake({
    provider,
    chainId,
    to: account,
    contract: stakingAddress,
  })

  const {
    data, isLoading: isBalanceLoading,
  } = useFetchBalancePerCurrencies({
    provider,
    fetchInfo: {
      address: account,
      currency: stakingAddress || ethers.constants.AddressZero,
    },
  })

  const stakingBalance = Array.isArray(data) ? data[0] : data

  const handleClick = async () => {
    if (
      !token.currency
      || (!token.approved && isStake)
      || !token.selectedAmount
      || !token.maxAmount
      || !stakingBalance
    ) return

    const txHash = await execute({
      action,
      amount: token.selectedAmount,
      isMax: stakingBalance.eq(token.selectedAmount),
    })
    if (txHash) {
      dispatch(resetDexAmount(TokenIndexer.STAKING))
    }
  }

  const { disabled, text, isFinall } = useMemo(() => {
    if (!token.selectedAmount) {
      return {
        disabled: true,
        text: 'Enter an amount',
      }
    }
    if (!token.maxAmount || token.selectedAmount?.gt(token.maxAmount)) {
      return {
        disabled: true,
        text: `Insufficient ${label || token.currency?.symbol} balance`,
      }
    }
    if (!isStake && stakingBalance?.lt(token.selectedAmount)) {
      return {
        disabled: true,
        text: `Insufficient ${label || token.currency?.symbol} staked amount to withdraw`,
      }
    }
    return {
      disabled: (!token.approved && isStake),
      text: actionMap[action]?.label,
      isFinall: true,
    }
  }, [action, token, label, isStake, stakingBalance])

  return (
    <SmartConnectorWrapper variant="outlined" color="secondary" size="large">
      <>
        {isFinall && isStake && (
          <ApproveButton
            indexer={TokenIndexer.STAKING}
            contract={stakingAddress}
            fullWidth
            sx={{ marginBottom: 1 }}
            {...props}
          />
        )}
        <ColorizedButton
          fullWidth
          loading={isLoading || isBalanceLoading}
          disabled={disabled}
          onClick={handleClick}
          {...props}
        >
          {text}
        </ColorizedButton>
      </>
    </SmartConnectorWrapper>
  )
}

export default StakingButton
