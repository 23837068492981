import { memo } from 'react'

import LoadingButton from '@mui/lab/LoadingButton'
import { constants } from 'ethers'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppSelector } from '../../app/hooks'
import { IDOClaim, useClaimIDO } from '../../hooks/launchpad'

interface ButtonProps {
  contract?: string
  vesting: IDOClaim
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const VestingButton = memo(({ contract, vesting, ...props }: ButtonProps): JSX.Element => {
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const { library: provider } = useWeb3React<Web3Provider>()
  const { claim, isLoading } = useClaimIDO({
    provider,
    chainId,
    to: account,
    contract,
  })
  const disabled = !vesting.isClaimable || vesting.amount.eq(constants.Zero) || vesting.claimedAmount.eq(vesting.amount)
  return (
    <LoadingButton
      color="error"
      size="small"
      disabled={disabled}
      loading={isLoading}
      onClick={() => claim({ account, idx: vesting.idx })}
      sx={{ height: 22, fontSize: '0.7rem' }}
      style={{ textTransform: 'none' }}
      {...props}
    >
      {disabled ? 'Not claimable' : 'Claim'}
    </LoadingButton>
  )
})

export default VestingButton
