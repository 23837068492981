import React, {
  useCallback, useMemo, useEffect, useState,
} from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'

import BootstrapDialogTitle from './BootstrapDialogTitle'
import ImgIcon from '../icons/ImgIcon'
import useFetchMetamaskAPI from '../../hooks/useFetchMetamaskAPI'
import { BridgeDirectionName } from '../../features/bridge/bridgeSlice'
import IBridgeRouterABI from '../../abi/IBridgeRouter.abi.json'
import {
  clearCurrent,
  CrossChainTransferStatus,
  CrossChainTransferStatusList,
  CrossChainTx,
  getCurrentBridgeTx,
  resetDepositTx,
  resetWithdrawTx,
  updateDepositData,
  updateSignedData,
  updateStatus,
  updateWithdrawData,
} from '../../features/wallet/transactionSlice'
import {
  getChainData, prettyFormat, sleep,
} from '../../helpers/utilities'
import { ChainData } from '../../helpers/types'

import MiningSvg from '../../assets/icons/mining.svg'
import MoonManSvg from '../../assets/icons/moon-man.svg'
import ConnectSvg from '../../assets/icons/connect.svg'
import { useCosignerSignaturesQuery } from '../../features/api/cosignerApiSlice'
import { PriceBox } from '../boxes'
import useWatchTx from '../../hooks/useWatchTx'

interface ContentProps {
  state: CrossChainTx
}

interface ContentWithProviderProps extends ContentProps {
  provider: Web3Provider
  lock: boolean
  setLock: React.Dispatch<React.SetStateAction<boolean>>
}

const getEstMinutesDescription = (chain?: ChainData) => {
  switch (chain?.chainId) {
    case 311752642:
    case 4216137055:
      return '~15-20 seconds'
    default:
      return '~2-3 minutes'
  }
}

const DepositContent = ({
  state, provider, lock, setLock,
}: ContentWithProviderProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { library: baseProvider } = useWeb3React<Web3Provider>()
  const { callContract, switchNetwork } = useFetchMetamaskAPI(baseProvider)
  const chainId = useAppSelector((rState) => rState.wallet.chainId)
  const depositChain = getChainData(state.deposit.currency.chainId)
  const withdrawChain = getChainData(state.withdraw.currency.chainId)

  // deposit monitor
  useWatchTx({
    provider,
    txHash: state.deposit.txHash,
    confirmations: state.deposit.confirmations,
    onSuccess: (receipt, confirmations) => (
      dispatch(updateDepositData({
        confirmations,
        status: CrossChainTransferStatus.COSIGNER_APPROVAL,
      }))
    ),
    onError: () => (
      dispatch(resetDepositTx())
    ),
  })

  const handleDeposit = async () => {
    if (state.deposit.txHash || lock) return

    setLock(true)

    let txHash: string | undefined

    // TODO: Add account switch if tx from is not a current account
    if (chainId !== state.deposit.currency.chainId) {
      const success = await switchNetwork(state.deposit.currency.chainId)
      if (!success) {
        setLock(false)
        return
      }
      // MetaMask switch delay
      // TODO: Fix this
      await sleep(3000)
    }

    if (state.deposit.currency.isNative) {
      txHash = await callContract({
        from: state.from,
        value: state.value,
        contract: {
          address: state.deposit.bridgeAddress,
          abi: IBridgeRouterABI,
          method: 'enterETH',
          params: [state.withdraw.currency.chainId],
        },
      })
    } else {
      txHash = await callContract({
        from: state.from,
        contract: {
          address: state.deposit.bridgeAddress,
          abi: IBridgeRouterABI,
          method: 'enter',
          params: [state.deposit.currency.address, state.value, state.withdraw.currency.chainId],
        },
      })
    }
    if (txHash) {
      dispatch(updateDepositData({
        txHash,
      }))
    }
    setLock(false)
  }

  const descrText = 'Deposit your token to the bridge in order to get '
    + `${state.deposit.currency.isNative ? 'bridged' : 'native'} token on ${withdrawChain?.name} later`

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>Initiate the deposit</Typography>
      </Grid>
      <Grid item xs={12}>
        {lock ? (
          <Typography>
            Initializing deposit transaction...
          </Typography>
        ) : (
          <Typography>
            {descrText}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <ImgIcon src={MiningSvg} width={100} height={100} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>Amount</Typography>
          <Typography>
            {prettyFormat(state.value, state.deposit.currency.decimals)}
            {' '}
            {state.deposit.currency.symbol}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>USD Value</Typography>
          <PriceBox currency={state.deposit.currency} value={state.value} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {state.deposit.txHash ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <CircularProgress color="secondary" size={14} sx={{ marginRight: 1 }} />
            <Typography>
              {`Awaiting confirmation from ${depositChain?.name}...`}
            </Typography>
          </Box>
        ) : (
          <LoadingButton
            fullWidth
            loading={lock}
            variant="outlined"
            size="large"
            color="secondary"
            loadingIndicator={<CircularProgress color="secondary" size={16} />}
            onClick={handleDeposit}
          >
            Deposit
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  )
}

const WithdrawContent = ({
  state, provider, lock, setLock,
}: ContentWithProviderProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { library: baseProvider } = useWeb3React<Web3Provider>()
  const { callContract, switchNetwork } = useFetchMetamaskAPI(baseProvider)
  const chainId = useAppSelector((rState) => rState.wallet.chainId)
  const chain = getChainData(state.withdraw.currency.chainId)

  // withdraw monitor
  useWatchTx({
    provider,
    txHash: state.withdraw.txHash,
    confirmations: state.withdraw.confirmations,
    onSuccess: (receipt, confirmations) => (
      dispatch(updateDepositData({
        confirmations,
        status: CrossChainTransferStatus.DONE,
      }))
    ),
    onError: () => (
      dispatch(resetWithdrawTx())
    ),
  })

  const handleWithdraw = async () => {
    if (state.withdraw.txHash || lock || !state.signedData) return

    setLock(true)

    // TODO: Add account switch if tx from is not a current account
    if (chainId !== state.withdraw.currency.chainId) {
      const success = await switchNetwork(state.withdraw.currency.chainId)
      if (!success) {
        setLock(false)
        return
      }
      // MetaMask switch delay
      // TODO: Fix this
      await sleep(3000)
    }

    const txHash = await callContract({
      from: state.to,
      contract: {
        address: state.withdraw.bridgeAddress,
        abi: IBridgeRouterABI,
        method: 'exit',
        params: [state.signedData.data, state.signedData.signatures],
      },
    })
    if (txHash) {
      dispatch(updateWithdrawData({
        txHash,
      }))
    }
    setLock(false)
  }

  const descrText = 'Withdraw your '
    + `${state.withdraw.currency.isNative ? 'native' : 'bridge'} token directly into wallet on ${chain?.name}`

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>Initiate the withdraw</Typography>
      </Grid>
      <Grid item xs={12}>
        {lock ? (
          <Typography>
            Initializing withdraw transaction...
          </Typography>
        ) : (
          <Typography>
            {descrText}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <ImgIcon src={MiningSvg} width={100} height={100} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>Amount</Typography>
          <Typography>
            {prettyFormat(state.value, state.withdraw.currency.decimals)}
            {' '}
            {state.withdraw.currency.symbol}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>USD Value</Typography>
          <PriceBox currency={state.withdraw.currency} value={state.value} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {state.withdraw.txHash ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <CircularProgress color="secondary" size={14} sx={{ marginRight: 1 }} />
            <Typography>
              {`Awaiting confirmation from ${chain?.name}...`}
            </Typography>
          </Box>
        ) : (
          <LoadingButton
            fullWidth
            loading={lock}
            variant="outlined"
            size="large"
            color="secondary"
            loadingIndicator={<CircularProgress color="secondary" size={16} />}
            onClick={handleWithdraw}
          >
            Withdraw
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  )
}

const DoneContent = ({ state }: ContentProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const handleClose = useCallback(() => {
    dispatch(clearCurrent())
    dispatch(closeModal())
  }, [dispatch])

  const sourceChain = getChainData(state.deposit.currency.chainId)
  const targetChain = getChainData(state.withdraw.currency.chainId)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>Cross-chain transfer completed</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {`Cross-chain transfer for ${prettyFormat(state.value, state.withdraw.currency.decimals)} `
            + `${state.withdraw.currency.symbol} from ${sourceChain?.name} to ${targetChain?.name} has been completed!`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <ImgIcon src={MoonManSvg} width={100} height={100} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          onClick={handleClose}
        >
          Exit
        </Button>
      </Grid>
    </Grid>
  )
}

const CosignerContent = ({ state }: ContentProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const { data: results = [] } = useCosignerSignaturesQuery({
    txHash: state.deposit.txHash || '',
    sourceChainId: state.deposit.currency.chainId,
    targetChainId: state.withdraw.currency.chainId,
  }, {
    pollingInterval: 15_000,
  })

  const chain = getChainData(state.deposit.currency.chainId)

  // NOTE: Always taking first as UI tx not used for complex smart contract exec
  // so we ar sure that it is one
  const signedData = results[0]

  useEffect(() => {
    async function updateCosignData() {
      if (!signedData || state.signedData) return

      dispatch(updateSignedData(signedData))

      // TODO: bad, replace to anothr approach
      await sleep(2000)

      dispatch(updateStatus(CrossChainTransferStatus.WITHDRAW_INITIATION))
    }
    updateCosignData()
  }, [dispatch, signedData, state.signedData])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>Syndicate cosigners verification</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Сosiners protect the cross-chain transaction from fraud and
          are necessary for the successful confirmation of the deposit.
          This process could take
          {' '}
          {getEstMinutesDescription(chain)}
          {' '}
          before it considered final.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <ImgIcon src={ConnectSvg} width={100} height={100} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {state.signedData ? (
          <Typography>
            Signatures found
          </Typography>
        ) : (
          <Typography>
            Awaiting for signatures
          </Typography>
        )}
        <CircularProgress color="secondary" size={14} sx={{ marginLeft: 1 }} />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontSize: 10, paddingTop: 1 }}>
          NOTE: this process required some minutes to get checkpoints, so it is safe to exit and resume later
        </Typography>
      </Grid>
    </Grid>
  )
}

const ProgressBarContent = (): JSX.Element => {
  const currentState = useAppSelector(getCurrentBridgeTx)

  const activeStep = currentState?.status || CrossChainTransferStatus.DEPOSIT_INITIATION

  const steps = useMemo(() => {
    if (!currentState) return []

    const sourceChain = getChainData(currentState.deposit.currency.chainId)
    const targetChain = getChainData(currentState.withdraw.currency.chainId)

    // should never occur, if happened, check currencies if they are correctly set
    if (!sourceChain || !targetChain) throw new Error('Chain names not found')

    return CrossChainTransferStatusList.map((status) => {
      switch (status) {
        case CrossChainTransferStatus.DEPOSIT_INITIATION:
          return `Initiate deposit on ${sourceChain.name}`
        case CrossChainTransferStatus.COSIGNER_APPROVAL:
          return 'Cosigners approval'
        case CrossChainTransferStatus.WITHDRAW_INITIATION:
          return `Initiate withdraw on ${targetChain.name}`
        case CrossChainTransferStatus.DONE:
          return 'Final'
        default:
          return 'Default'
      }
    })
  }, [currentState])

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{
        p: 2,
      }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

const BridgeTransferModal = (): JSX.Element => {
  const [lock, setLock] = useState(false)
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.BRIDGE_TRANSFER])
  const currentState = useAppSelector(getCurrentBridgeTx)

  const { library: depositProvider } = useWeb3React<Web3Provider>(BridgeDirectionName.SOURCE)
  const { library: withdrawProvider } = useWeb3React<Web3Provider>(BridgeDirectionName.TARGET)

  const handleClose = useCallback(() => {
    if (lock) return
    dispatch(closeModal())
    dispatch(clearCurrent())
  }, [dispatch, lock])

  const stateContent = useMemo((): JSX.Element | null => {
    if (
      !currentState
      || !depositProvider
      || !withdrawProvider
    ) return null

    switch (currentState.status) {
      case CrossChainTransferStatus.DEPOSIT_INITIATION:
        return <DepositContent state={currentState} provider={depositProvider} lock={lock} setLock={setLock} />
      case CrossChainTransferStatus.COSIGNER_APPROVAL:
        return <CosignerContent state={currentState} />
      case CrossChainTransferStatus.WITHDRAW_INITIATION:
        return <WithdrawContent state={currentState} provider={withdrawProvider} lock={lock} setLock={setLock} />
      case CrossChainTransferStatus.DONE:
        return <DoneContent state={currentState} />
      default:
        return null
    }
  }, [currentState, depositProvider, withdrawProvider, lock])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="bridge-transfer-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
      transitionDuration={{
        exit: 0,
      }}
    >
      <BootstrapDialogTitle id="bridge-transfer-dialog-title">
        <ProgressBarContent />
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ p: 8, paddingTop: 3, paddingBottom: 3 }}>
        {stateContent}
      </DialogContent>
    </Dialog>
  )
}

export default BridgeTransferModal
