import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { ethers } from 'ethers'
import { useAppSelector } from '../../../app/hooks'
import { useFetchDexCurrencies } from '../../../hooks'
import { useGetPair } from '../../../hooks/dex'
import { useGetFarmInfo } from '../../../hooks/governance'
import { PageBox, StakeFormBox } from '../../boxes'
import ImgIcon from '../../icons/ImgIcon'
import {
  getDumbLogoURI,
  getGovernanceInfo, getPrioritySymbol, prettyFormat,
} from '../../../helpers/utilities'
import { getLpCurrency, getMegaPoolCurrency } from '../../../entities/utils'
import {
  FarmInfoPaper, FarmWarningPaper,
} from '../../papers'
import { ClaimButton } from '../../buttons'
import useWeb3Provider from '../../../hooks/useProjectProvider'

const Farm = (): JSX.Element => {
  const { account } = useAppSelector((state) => state.wallet)
  const { token0, token1, farm } = useParams()
  const { getCurrency } = useFetchDexCurrencies()

  const currency0 = getCurrency(undefined, token0 || '')
  const currency1 = getCurrency(undefined, token1 || '')

  const chainId = (currency0?.address === ethers.constants.AddressZero)
    ? currency1?.chainId
    : currency0?.chainId
  const { currency: govCurrency } = getGovernanceInfo(chainId)
  const farmAddress = farm || ethers.constants.AddressZero

  const provider = useWeb3Provider(chainId)

  const {
    address: pair,
    isLoading: isPairLoading,
  } = useGetPair({ provider, currency0, currency1 })

  const pairAddress = pair || ethers.constants.AddressZero

  const {
    farmData, positionData, isLoading: isFarmLoading,
  } = useGetFarmInfo({
    provider,
    pairAddress,
    farmAddress,
    account,
    chainId,
  })

  const stakeCurrency = (pair && chainId) ? getLpCurrency(chainId, pairAddress) : undefined
  const farmCurrency = (farm && chainId) ? getMegaPoolCurrency(chainId, farmAddress) : undefined

  const isLoading = isPairLoading || isFarmLoading

  return (
    <PageBox mt={2}>
      <Grid
        container
        sx={{ maxWidth: 750 }}
        spacing={{
          md: 2,
          xs: 0,
        }}
      >
        <Grid
          item
          xs={12}
          md={9}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              md: 'flex-start',
              xs: 'center',
            },
          }}
        >
          {isLoading ? (
            <CircularProgress
              color="secondary"
              size={24}
              sx={{ margin: 0.5 }}
            />
          ) : (
            <Typography variant="h6" sx={{ margin: 0.5 }}>
              {currency0 && currency1 ? (
                `${currency0?.symbol} - ${currency1?.symbol} Liquidity mining`
              ) : (
                'Liquidity mining'
              )}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              md: 'flex-end',
              xs: 'center',
            },
          }}
        >
          <Box sx={{ margin: 0.5 }}>
            <ImgIcon src={currency0?.logoURI || getDumbLogoURI()} />
          </Box>
          <Box sx={{ margin: 0.5 }}>
            <ImgIcon src={currency1?.logoURI || getDumbLogoURI()} />
          </Box>
        </Grid>
        {(farmData?.balance.eq(ethers.constants.Zero) && !farmData?.staked.gt(ethers.constants.Zero)) && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {currency0 && currency1 ? (
              <FarmWarningPaper
                sx={{
                  maxWidth: 750,
                  marginLeft: { xs: 2, md: 0 },
                  marginRight: { xs: 2, md: 0 },
                }}
              >
                <Box p={2}>
                  <Typography variant="h6" gutterBottom>
                    {`Get ${currency0?.symbol} - ${currency1?.symbol} LP liquidity tokens`}
                  </Typography>
                  <Typography variant="body2">
                    {`${currency0?.symbol} - ${currency1?.symbol} LP tokens required. `}
                    Once you have added liquidity to the
                    {` ${currency0?.symbol} - ${currency1?.symbol} `}
                    pool you can stake your liquidity tokens on this page.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    href={`#/add/${getPrioritySymbol(currency0)}/${getPrioritySymbol(currency1)}`}
                    sx={{
                      mt: 3,
                    }}
                  >
                    Add Liquidity
                  </Button>
                </Box>
              </FarmWarningPaper>
            ) : (
              <Box
                p={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress
                  color="secondary"
                  size={24}
                  sx={{
                    marginTop: 3,
                  }}
                />
              </Box>
            )}
          </Grid>
        )}
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FarmInfoPaper
            elevation={4}
            sx={{ marginLeft: { xs: 2, md: 0 }, marginRight: { xs: 2, md: 0 } }}
          >
            <Box p={2}>
              <Typography variant="subtitle2">Total deposits</Typography>
              {isLoading ? (
                <CircularProgress color="secondary" size={24} sx={{ marginTop: 0.4 }} />
              ) : (
                <Typography variant="h6">
                  {`$${positionData?.farmPoolBalanceInUSD.toUnsafeFloat().toFixed(2).toLocaleString() || 0}`}
                </Typography>
              )}
            </Box>
          </FarmInfoPaper>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FarmInfoPaper
            elevation={4}
            sx={{ marginLeft: { xs: 2, md: 0 }, marginRight: { xs: 2, md: 0 } }}
          >
            <Box p={2}>
              <Typography variant="subtitle2">Pool rate</Typography>
              {isLoading ? (
                <CircularProgress color="secondary" size={24} sx={{ marginTop: 0.4 }} />
              ) : (
                <Typography variant="h6">
                  {`${(farmData)
                    ? prettyFormat(farmData?.weeklyRewardRate, govCurrency?.decimals, 4).toLocaleString()
                    : 0} ${govCurrency?.symbol || '-'} / week`}
                </Typography>
              )}
            </Box>
          </FarmInfoPaper>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FarmInfoPaper
            elevation={4}
            sx={{ marginLeft: { xs: 2, md: 0 }, marginRight: { xs: 2, md: 0 } }}
          >
            <Box p={2}>
              <Typography variant="subtitle2">Your liquidity deposits</Typography>
              {isLoading ? (
                <CircularProgress color="secondary" size={24} sx={{ marginTop: 0.4 }} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6">
                    {(farmData) ? prettyFormat(farmData?.staked, stakeCurrency?.decimals, 8) : 0}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '.7rem' }}>
                    {`${currency0?.symbol || '-'} - ${currency1?.symbol || '-'} LP `}
                  </Typography>
                </Box>
              )}
            </Box>
          </FarmInfoPaper>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FarmInfoPaper
            elevation={4}
            sx={{ marginLeft: { xs: 2, md: 0 }, marginRight: { xs: 2, md: 0 } }}
          >
            <Box p={2}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subtitle2">Your unclaimed rewards</Typography>
                <ClaimButton
                  variant="outlined"
                  disabled={!farmData || farmData.earned.eq(ethers.constants.Zero) || !farmCurrency}
                  contract={farmCurrency?.address}
                  action="getRewards"
                />
              </Box>
              {isLoading ? (
                <CircularProgress color="secondary" size={24} sx={{ marginTop: 0.4 }} />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6">
                    {(farmData) ? prettyFormat(farmData.earned, stakeCurrency?.decimals, 8) : 0}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '.7rem' }}>
                    {`${(farmData)
                      ? prettyFormat(farmData.userWeeklyRewardRate, stakeCurrency?.decimals, 4).toLocaleString()
                      : 0} ${govCurrency?.symbol || '-'} / week`}
                  </Typography>
                </Box>
              )}
            </Box>
          </FarmInfoPaper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StakeFormBox
            stakeCurrency={stakeCurrency}
            farmCurrency={farmCurrency}
            sx={{
              maxWidth: 750,
              marginLeft: { xs: 2, md: 0 },
              marginRight: { xs: 2, md: 0 },
            }}
            label={(currency0 && currency1) ? `${currency0?.symbol} - ${currency1?.symbol} LP` : '- LP'}
          />
        </Grid>
      </Grid>
    </PageBox>
  )
}

export default Farm
