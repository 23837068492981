import { ethers } from 'ethers'
import {
  useWeb3RequestQuery,
} from '../../features/api/web3ApiSlice'

import IMulticall2ABI from '../../abi/IMulticall2.abi.json'
import { HookProvider } from '..'

interface Call {
  target: string
  callData: string
}

interface M2Props {
  provider?: HookProvider
  address: string
  params: Array<Call>
  requireSuccess?: boolean
  pollingInterval?: number
  skip?: boolean
}

interface M2Response {
  response?: string[]
  isLoading: boolean
  isFetching: boolean
  isError: boolean
}

const useMulticall2 = ({
  provider, address, params, requireSuccess = false, skip = false,
}: M2Props): M2Response => {
  const {
    data, isLoading, isFetching, isError,
  } = useWeb3RequestQuery({
    provider,
    request: {
      address,
      abi: IMulticall2ABI,
      method: 'tryAggregate',
      params: [
        requireSuccess,
        params,
      ],
    },
  }, {
    pollingInterval: 10_000,
    skip: !provider || !params || !address || address === ethers.constants.AddressZero || skip,
  })

  const response = (!data)
    ? undefined
    : (data as { returnData: string, success: boolean }[]).map((res) => res.returnData)

  return {
    response,
    isLoading,
    isFetching,
    isError,
  }
}

export default useMulticall2
