import React from 'react'
import { useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import CircularProgress from '@mui/material/CircularProgress'
import { useMediaQuery } from '@mui/material'

import { IDOFilter, useFetchIDOPools } from '../../../hooks/launchpad'
import { PageBox } from '../../boxes'
import { IDOSelectPaper } from '../../papers'

const IDOFilterButton = ({
  upcomming,
  ended,
  canceled,
}: {
  upcomming: number,
  ended: number,
  canceled: number,
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filter = searchParams.get('filter') || 'upcomming'

  const isTablet = useMediaQuery('(max-width:600px)', {
    noSsr: true,
  })

  const handleFilter = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string | null,
  ) => {
    if (newFilter !== null) {
      setSearchParams({
        filter: newFilter,
      })
    }
  }

  return (
    <ToggleButtonGroup
      value={filter}
      exclusive
      onChange={handleFilter}
      aria-label="IDO filter"
      fullWidth
      color="secondary"
      orientation={isTablet ? 'vertical' : 'horizontal'}
      size="small"
    >
      <ToggleButton value="upcomming">
        {`Upcomming [${upcomming}]`}
      </ToggleButton>
      <ToggleButton value="ended">
        {`Ended [${ended}]`}
      </ToggleButton>
      <ToggleButton value="canceled">
        {`Canceled [${canceled}]`}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const FindIDO = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const filter = (searchParams.get('filter') || 'upcomming') as unknown as IDOFilter

  const { pools, count, isLoading } = useFetchIDOPools({
    filter,
  })

  return (
    <PageBox
      sx={{
        margin: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 1150,
        alignItems: 'center',
      }}
    >
      <Box
        mt={2}
        mb={2}
      >
        <Typography
          variant="h6"
          color="secondary"
          sx={{
            fontWeight: 600,
          }}
        >
          Available IDOs
        </Typography>
      </Box>
      <Box
        sx={{
          width: {
            sm: '40vh',
            xs: '20vh',
          },
        }}
        mb={4}
      >
        <IDOFilterButton {...count} />
      </Box>
      <Grid
        container
        spacing={4}
      >
        {isLoading ? (
          <Grid
            item
            xs={12}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
              }}
            >
              <CircularProgress color="secondary" size={36} />
            </Box>
          </Grid>
        ) : (
          <>
            {pools.map((pool) => (
              <Grid
                key={pool.id}
                item
                xs={12}
                md={4}
                sm={6}
              >
                <IDOSelectPaper pool={pool} />
              </Grid>
            ))}
            {!pools.length && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body2">No IDOs have been found</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </PageBox>
  )
}

export default FindIDO
