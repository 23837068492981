import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'

import { supportedChains } from './chains'
import { DEFAULT_CHAIN_ID } from './constants'

const supportedChainIds = supportedChains.map((chain) => chain.chainId)

const RPC_URLS = supportedChains.reduce((urls, chain) => {
  urls[chain.chainId] = chain.rpcURL
  return urls
}, {} as { [chainId: number]: string })

export const injected = new InjectedConnector({ supportedChainIds })

export const sourceNetwork = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: DEFAULT_CHAIN_ID,
})

export const targetNetwork = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: supportedChains.filter((chain) => chain.chainId === DEFAULT_CHAIN_ID)[0].defaultTargetChainId,
})

export const refetcher = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: DEFAULT_CHAIN_ID,
})

export const multipleConnector = supportedChains.reduce((store, chain) => {
  store[chain.chainId] = new NetworkConnector({
    urls: {
      [chain.chainId]: chain.rpcURL,
    },
    defaultChainId: chain.chainId,
  })
  return store
}, {} as { [chainId: number]: NetworkConnector })

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  chainId: DEFAULT_CHAIN_ID,
  qrcode: true,
})

export const bscConnector = new BscConnector({ supportedChainIds })

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: 'MoonbaSwap',
  appLogoUrl: 'https://moonba.co/favicon.webp',
  supportedChainIds,
})

export enum ConnectorNames {
  Injected = 'Injected',
  SourceNetwork = 'SourceNetwork',
  TargetNetwork = 'TargetNetwork',
  Refetcher = 'Refetcher',
  WalletConnect = 'WalletConnect',
  WalletLink = 'WalletLink',
  BSC = 'BSC',
}

export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.SourceNetwork]: sourceNetwork,
  [ConnectorNames.TargetNetwork]: targetNetwork,
  [ConnectorNames.Refetcher]: refetcher,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
  [ConnectorNames.BSC]: bscConnector,
}
