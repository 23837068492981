import { useState } from 'react'
import Typed from 'react-typed'
import VisibilitySensor from 'react-visibility-sensor'

import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import Fade from '@mui/material/Fade'

import {
  ArtemisSvg,
  HeadingSvg,
  FastSvg,
  DiscountSvg,
  OneClickSvg,
  SoftBankSvg,
  LimitSvg,
  SwapSvg,
  GovernanceSvg,
  ValidatorSvg,
} from '../../../../assets/icons/about'

import {
  TelegramIcon,
  LinkedInIcon,
  TwitterIcon,
  GitIcon,
  MediumIcon,
  DiscordIcon,
  YouTubeIcon,
  EmailIcon,
} from '../../../../assets/icons/social'

import {
  GCPLogo,
  IBMLogo,
  KuCoinLogo,
} from '../../../../assets/icons/about/partnership'

import ImgIcon from '../../../icons/ImgIcon'

interface TextBlockProps {
  header: string
  description: string | string[]
  additionalComponent?: () => JSX.Element
}

interface InfoBlockProps extends TextBlockProps {
  imageURL: string
  useRight?: boolean
}

const links = [
  {
    name: 'Email',
    url: 'mailto:hello@moonba.co',
    src: EmailIcon,
  },
  //  {
  //    name: 'Docs',
  //    url: 'https://docs.moonba.co',
  //    src: GitbookIcon,
  //  },
  {
    name: 'Telegram',
    url: 'https://t.me/Moonbaglobal',
    src: TelegramIcon,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/moonba',
    src: LinkedInIcon,
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/channel/UCagnLjdapsgf_yzBIk8Iccw',
    src: YouTubeIcon,
  },
  {
    name: 'GitHub',
    url: 'https://github.com/moonba-co',
    src: GitIcon,
  },
  {
    name: 'Discord',
    url: 'https://discord.gg/BvBTgJh4bY',
    src: DiscordIcon,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/GoMoonba',
    src: TwitterIcon,
  },
  {
    name: 'Medium',
    url: 'https://medium.com/@Moonba',
    src: MediumIcon,
  },
]

const textInfoList: InfoBlockProps[] = [
  {
    header: 'Faster Trading',
    description: `MoonbaSwap is a cross-chain AMM DEX protocol for swapping tokens based on OneLedger and 
its bridged chains, this removes ‘trusted’ intermediaries and provides the platform for faster trading.
MoonbaSwap  can produce a throughput of about 4000 tps , which is a lot faster
than what other dexs can offer.`,
    imageURL: FastSvg,
  },
  {
    header: 'Lower Cost',
    description: `The native gas fee charged for the Moonba protocol is much less than the fee charged 
on other blockchains.`,
    imageURL: DiscountSvg,
    useRight: true,
  },
  {
    header: 'One Click Transactions',
    description: `While Moonba is a DEX, it does more than just facilitate digital asset trades.
MoonbaSwap  offers a variety of services aimed at assisting users in their growth, 
including  a future launch one-click cross-chain transaction.`,
    imageURL: OneClickSvg,
  },
  {
    header: 'Limitless Liquidity',
    description: [
      `MoonbaSwap combines multiple liquidity sources under a common liquidity aggregation algorithm, 
operating completely on-chain, in a trustless and decentralized way.`,
      `The liquidity providers for the MoonbaSwap protocol are incentivised through the native rewards token, 
which provides them more motivation to add liquidity and keep the DEX running.`,
      `Anyone can contribute new sources of liquidity to MoonbaSwap. As an open source application contributions can 
be made directly to the MoonbaSwap codebase.`,
    ],
    imageURL: LimitSvg,
    useRight: true,
  },
  {
    header: 'Swap Any Token',
    description: [
      `Increase the number of tokens available on MoonbaSwap by including tokens from 
the web3 ecosystem as well as tokens from other blockchains.`,
      'With Metamask Swaps integration users access all decentralized liquidity sources in one place.',
    ],
    imageURL: SwapSvg,
  },
  {
    header: 'MBA Tokens',
    description: [
      `MBA is a utility and governance token used to reward liquidity providers on MoonbaSwap. 
Unlike most other reward tokens, MBA is burned with transactions and decreases in supply over time.`,
      `The 0.3% trading fee for every swap on the MoonbaSwap is used to buy back $MBA tokens, which are then burned. 
At first, 85% of burned $MBA tokens are reminted to allocate to liquidity providers, 
but with time this percentage will decrease to zero over a 5 year period.`,
      `The 100% of gas fee charged in OLT would be burned. The gas model for OLT is thus deflationary in nature 
because the circulation is decreased by the amount burned in gas fees each day.`,
    ],
    imageURL: GovernanceSvg,
    useRight: true,
  },
  {
    header: 'Become a Validator/Delegator',
    description: [
      `MoonbaSwap is built on the OneLedger Network, focusing on interoperability to connect the rest of 
the crypto ecosystem.`,
      `The OneLedger public chain has been built on DPOS consensus with great rewards on staking and delegation. 
The Community can benefit from low cost of transaction fees, high performance of the the network, 
interoperability with other chains, delegation and staking rewards and the superb functionality of OneWallet.`,
    ],
    imageURL: ValidatorSvg,
    additionalComponent: (): JSX.Element => (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
          mb={2}
        >
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/OneLedgerTech/status/1347619762449297408"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <img alt="partner" src={KuCoinLogo} width={90} />
          </Link>
          <Typography
            variant="body1"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            &nbsp;is largest validator at the moment.
          </Typography>
        </Box>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          href="https://docs.oneledger.io/docs/develop/node-deployment/docker-validator-deployment"
          target="_blank"
        >
          Read the docs
        </Button>
      </>
    ),
  },
]

const TextBlock = ({
  header, description, additionalComponent: AdditionalComponent,
}: TextBlockProps): JSX.Element => (
  <Grid
    item
    xs={12}
    sm={6}
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Typography variant="h3" sx={{ fontWeight: 700 }} gutterBottom>
      {header}
    </Typography>
    {Array.isArray(description) ? (
      <>
        {description.map((desc) => (
          <Typography key={desc} variant="body1" gutterBottom>
            {desc}
          </Typography>
        ))}
      </>
    ) : (
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
    )}
    {AdditionalComponent && (
      <AdditionalComponent />
    )}
  </Grid>
)

const ImgBlock = ({ imageURL, imageWidth }: { imageURL: string, imageWidth?: string | number }): JSX.Element => (
  <Grid
    item
    xs={12}
    sm={6}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: { xs: 5, sm: 0 },
    }}
  >
    <img alt="preview" src={imageURL} width={imageWidth} />
  </Grid>
)

const InfoBlock = ({
  header, description, imageURL, useRight, additionalComponent,
}: InfoBlockProps): JSX.Element => {
  const theme = useTheme()
  const [active, setActive] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)', {
    noSsr: true,
  })
  const imgWidth = isMobile ? '90%' : '50%'

  let backgroundColor: string | undefined
  let color: string | undefined

  if (useRight) {
    if (theme.palette.mode === 'light') {
      backgroundColor = '#fff'
      color = theme.palette.secondary.main
    } else {
      backgroundColor = theme.palette.primary.main
      color = '#fff'
    }
  } else {
    backgroundColor = theme.palette.secondary.main
    color = theme.palette.primary.main
  }

  return (
    <Box
      sx={{
        backgroundColor,
        color,
      }}
    >
      <VisibilitySensor
        partialVisibility
        onChange={(isActive) => {
          if (!isActive) return
          setActive(true)
        }}
      >
        <Fade in={active} timeout={1500}>
          <Grid
            container
            p={8}
          >
            {(useRight || isMobile) ? (
              <>
                <ImgBlock imageURL={imageURL} imageWidth={imgWidth} />
                <TextBlock header={header} description={description} additionalComponent={additionalComponent} />
              </>
            ) : (
              <>
                <TextBlock header={header} description={description} additionalComponent={additionalComponent} />
                <ImgBlock imageURL={imageURL} imageWidth={imgWidth} />
              </>
            )}
          </Grid>
        </Fade>
      </VisibilitySensor>
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PartnershipBlock = (): JSX.Element => {
  const theme = useTheme()

  let backgroundColor: string | undefined
  let color: string | undefined

  if (theme.palette.mode === 'light') {
    backgroundColor = '#fff'
    color = theme.palette.secondary.main
  } else {
    backgroundColor = theme.palette.primary.main
    color = '#fff'
  }

  return (
    <Box
      sx={{
        backgroundColor,
        color,
      }}
    >
      <Grid
        container
        p={8}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginBottom: { xs: 5, sm: 0 },
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: 700 }} gutterBottom>
            Partnership
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.oneledger.io/oneledger_googlecloudplatform?hsLang=en"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img alt="partner" src={GCPLogo} width="50%" />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://blog.oneledger.io/oneledger_ibm?hsLang=en"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img alt="partner" src={IBMLogo} width="50%" />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="
              https://www.businesswire.com/news/home/20211117005623/en/KuCoin-Labs-Launches-100-Million-Metaverse-Fund-
              To-Empower-Early-Stage-Metaverse-Projects
              "
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img alt="partner" src={KuCoinLogo} width="50%" />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const Home = (): JSX.Element => {
  const theme = useTheme()
  return (
    <Box>
      <Grid
        container
        p={4}
        pt={8}
        pb={8}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            alignItems: 'top',
            justifyContent: 'center',
          }}
        >
          <Box mr={4}>
            <img alt="preview" src={HeadingSvg} width="100%" />
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: 30, sm: 60 }, fontWeight: 700, color: '#c58a2a' }}
            gutterBottom
          >
            MoonbaSwap
          </Typography>
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: 20, sm: 40 }, fontWeight: 700 }}
          >
            The DEX for interoperable trading
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: 20, sm: 40 },
              height: 80,
              fontWeight: 700,
              marginBottom: { xs: 2, sm: 4 },
            }}
          >
            Stay connected to your assets
            {' - '}
            <Typography
              color="secondary"
              component="span"
              variant="inherit"
            >
              <Typed
                strings={['more to earn!', 'less to spend!']}
                typeSpeed={60}
                loop
              />
            </Typography>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              href="MoonbaSwap-Whitepaper.pdf"
              color="secondary"
              variant="outlined"
              size="large"
              title="Read whitepaper"
              target="_blank"
            >
              Read whitepaper
            </Button>
          </Box>
        </Grid>
      </Grid>
      {textInfoList.map((textInfo, index) => (
        <InfoBlock key={index.toString()} {...textInfo} />
      ))}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderTop: `solid 1px ${theme.palette.secondary.main}`,
        }}
      >
        <Grid
          container
          p={8}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Our community grows stronger every day. Please follow our social platforms to get the most up-to-date,
              accurate MBA information.
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Using the links below, you can join our various groups alongside the other members of the Moonba.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              mt={4}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: '75hv',
                }}
              >
                {links.map((link) => (
                  <Link
                    key={link.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={link.url}
                    m={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <ImgIcon src={link.src} width={36} height={36} />
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: 1,
                        color: (theme.palette.mode === 'light') ? theme.palette.secondary.main : '#fff',
                      }}
                    >
                      {link.name}
                    </Typography>
                  </Link>
                ))}
              </Box>
              <Box
                mt={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: '75hv',
                }}
              >
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.artemisclouds.com/audit_en/202203310496.html"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <ImgIcon src={ArtemisSvg} width={36} alt="Audit" />
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 1,
                      color: (theme.palette.mode === 'light') ? theme.palette.secondary.main : '#fff',
                    }}
                  >
                    Security Auditor&nbsp;&nbsp;
                  </Typography>
                </Link>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://medium.com/@Moonba/moonbaswap-softbank-reach-strategic-cooperation-ab22403fbe7b"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <ImgIcon src={SoftBankSvg} width={72} alt="SoftBank" />
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 1,
                      color: (theme.palette.mode === 'light') ? theme.palette.secondary.main : '#fff',
                    }}
                  >
                    Partnership
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Home
